export default [
  {
    path: '/coletas',
    name: 'Collects.index',
    component: () => import(/* webpackChunkName: "Collects.index" */ './pages/Index.vue')
  },
  {
    path: '/coletas/:id',
    name: 'Collects.edit',
    component: () => import(/* webpackChunkName: "Collects.edit" */ './components/EditCollect.vue')
  }
];