import authorization from '@/modules/auth'
import store from '@/store'

export default [
  {
    path: '/ctes',
    name: 'cte.index',
    component: () => import(/* webpackChunkName: "cte.index" */ './pages/Index.vue')
  },
  {
    path: '/adicionar-cte',
    name: 'cte.create',
    component: () => import(/* webpackChunkName: "cte.create" */ './pages/Create.vue')
  },
  {
    path: '/editar-cte/:id',
    name: 'cte.edit',
    component: () => import(/* webpackChunkName: "cte.edit" */ './pages/Edit.vue')
  },
  {
    path: '/consulta-cte',
    name: 'cte.show',
    component: () => import(/* webpackChunkName: "cte.show" */ './pages/Show.vue')
  },
  {
    path: '/entregas-regiao',
    name: 'cte.regions',
    component: () => import(/* webpackChunkName: "cte.regions" */ './pages/Regions.vue')
  },
  {
    path: '/enviar-arquivo-ctes',
    name: 'cte.sendFile',
    component: () => import(/* webpackChunkName: "cte.sendFile" */ './pages/sendFile.vue'),
    beforeEnter: (to, from, next) => {
      if(authorization.isAuthorized('manager')) {
        return next()
      } else {
				store.dispatch('logout');
      }
    }
  },
  {
    path: '/chegada-atrasados',
    name: 'cte.ArrivalLate',
    component: () => import(/* webpackChunkName: "cte.arrivalLate" */ './reports/ArrivalLate.vue')
  },
  {
    path: '/ctes-sem-taxa',
    name: 'cte.CtesWithoutFees',
    component: () => import(/* webpackChunkName: "cte.ctesWithoutFees" */ './reports/Index.vue')
  },
]