<template>
  <div>
    <v-card>
      <v-card-title>Pesquisar CTe</v-card-title>
      <v-card-text>
        <v-form ref="formGlobalSearch" @submit.prevent="searchCte">
          <v-text-field
            ref="inputCteGlobal"
            label="CTe"
            v-model="cte"
            :rules="[v => !! v || 'Preencha o CTe']"
            autofocus
          ></v-text-field>
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
          >Pesquisar</v-btn>
          <v-row>
            <v-col cols="12">
              <v-data-table
                hide-default-footer
                disable-pagination
                dense
                :items="ctes"
                :headers="headers"
                v-if="ctes.length > 0"
                @click:row="(e) => goto(e.id)"
              >
                <template v-slot:[`item.nf`]="{ item }">
                  <span>{{ $functions.cutName(item.nf, 10) }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import http from "@/modules/http";

export default {
  mounted() {
    this.$refs.inputCteGlobal.focus();
  },
  data() {
    return {
      cte: '',
      loading: false,
      ctes: [],
      headers: [
        {text: 'CTe', value: 'number'},
        {text: 'NF', value: 'nf'},
        {text: 'Destino', value: 'recipients.name'},
      ]
    }
  },
  methods: {
    searchCte() {
      if(this.$refs.formGlobalSearch.validate()) {
        this.loading = true;
        http.get('api/ctes-filter?cte='+this.cte)
        .then(res => {
          this.loading = false;
          if(res.data.length === 1) {
            this.goto(res.data[0].id);
          } else if(res.data.length > 1) {
            this.mountTable(res.data);
          }
        })
      }
    },
    mountTable(data) {
      this.ctes = data;
    },
    goto(id) {
      this.ctes = [];
      this.$refs.formGlobalSearch.reset();
      this.$router.push('/editar-cte/'+id);
      this.$emit('close');
    },
  },
}
</script>

<style scoped>
</style>