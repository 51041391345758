var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Comissões fechadas")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"error","fab":"","small":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-keyboard-return")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","fab":"","small":""},on:{"click":function($event){return _vm.$router.push({ name: 'commissions.index' })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"secondary","fab":"","small":""},on:{"click":_vm.getCommissions}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary"},on:{"click":_vm.handleShowFilters}},[_vm._v("Filtrar")]),_c('div',{staticClass:"filter"},[_c('button',{staticClass:"close-button",on:{"click":_vm.handleShowFilters}},[_vm._v("x")]),_c('v-form',[_c('v-select',{attrs:{"label":"Parceira","items":_vm.partners,"loading":_vm.partners.length < 1,"item-text":"name","item-value":"id"},model:{value:(_vm.params.partner),callback:function ($$v) {_vm.$set(_vm.params, "partner", $$v)},expression:"params.partner"}}),_c('v-text-field',{attrs:{"label":"Data inicial","type":"date"},model:{value:(_vm.params.initial),callback:function ($$v) {_vm.$set(_vm.params, "initial", $$v)},expression:"params.initial"}}),_c('v-text-field',{attrs:{"label":"Data final","type":"date"},model:{value:(_vm.params.final),callback:function ($$v) {_vm.$set(_vm.params, "final", $$v)},expression:"params.final"}}),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading},on:{"click":_vm.filterCommissions}},[_vm._v("Filtrar")]),_c('v-btn',{attrs:{"color":"error","loading":_vm.loading},on:{"click":_vm.removeFilters}},[_vm._v("Remover Filtro")])],1)],1),_c('v-data-table',{attrs:{"items":_vm.commissions,"headers":[
      {text: 'ID', value: 'id'},
      {text: 'Parceira', value: 'partner.name'},
      {text: 'Período', value: 'date'},
      {text: 'Comissões', value: 'total_commission'} ],"dense":"","disable-pagination":"","hide-default-footer":"","loading":_vm.loading,"item-class":function () { return "cursor-pointer"; }},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.initial_date,"DD/MM/YYYY"))+" à "+_vm._s(_vm._f("moment")(item.final_date,"DD/MM/YYYY"))+" ")])]}},{key:"item.total_commission",fn:function(ref){
    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$functions.moneyFormat(item.total_commission))+" ")])]}}],null,true)}),_c('v-pagination',{attrs:{"length":_vm.pagination.total},on:{"input":_vm.onPageChange},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }