<template>
	<div>
		<h1>Regras de clientes para coletas</h1>

		<v-btn
			color="success"
			fab
			small
			class="ma-2"
			@click="$router.push('/regras-clientes/adicionar')"
		>
			<v-icon>mdi-plus</v-icon>
		</v-btn>

		<v-btn
			color="secondary"
			fab
			small
			class="ma-2"
			@click="getCustomerRules"
		>
			<v-icon>mdi-refresh</v-icon>
		</v-btn>

		<v-data-table
			:loading="loading"
			disable-pagination
			hide-default-footer
			dense
			:items="items"
			:headers="[
				{text: 'Nome', value: 'rule_name'},
				{text: 'Código', value: 'rule_code'},
				{text: 'Parceira', value: 'partners.name'},
				{text: 'Porcentagem', value: 'percentage'},
				{text: 'Frete Mínimo', value: 'min_freight'},
				{text: 'Desconto ICMS', value: 'discount_icms'},
				{text: 'Ações', value: 'actions'},
			]"
		>
			<template v-slot:[`item.min_freight`]="{ item }">
				<span>{{ $functions.moneyFormat(item.min_freight) }}</span>
			</template>

			<template v-slot:[`item.discount_icms`]="{ item }">
				<span>{{ item.discount_icms ? 'Sim' : 'Não' }}</span>
			</template>

			<template v-slot:[`item.actions`]="{ item }">
				<Delete @deleted="getCustomerRules" :rule="item"/>
			</template>
		</v-data-table>
	</div>
</template>

<script>
import Delete from '../components/DeleteCustomerRule.vue'
	export default {
		components: {
			Delete,
		},
		mounted() {
			this.getCustomerRules();
		},
		data() {
			return {
				loading: false,
				items: [],
			} 
		},
		methods: {
			getCustomerRules() {
				this.loading = true;
				this.$http.get(`api/customer-rules`)
					.then(({ data }) => {
						this.loading = false;
						this.items = data;
					}).catch(err => {
						this.loading = false;
						this.$side({
							type: 'error',
							msg: 'Erro ao carregar regras'
						});
					});
			}
		}
	}
</script>