<template>
  <div>
    <div>
      <v-row>
        <v-col cols="12" md="6">
          <span @click="routerGo('/')" class="link-home">
            <v-icon class="mx-2">mdi-home</v-icon>
            Home
          </span>
        </v-col>
        <v-col cols="12" md="6">
          <span @click="routerGo('/configuracoes')" class="link-home">
            <v-icon class="mx-2">mdi-cog-outline</v-icon>
            Configurações
          </span>
        </v-col>
      </v-row>

      <div
        :disabled="!enabled"
        v-for="(m, i) in menus"
        :key="i"
        style="background: #10b97d"
      >
        <div class="header-menu" v-if="$auth.isAuthorized(m.rule)">
          {{ m.name }}
        </div>
        <div
          class="d-flex flex-wrap"
          v-if="$auth.isAuthorized(m.rule)"
          style="background: rgb(18, 146, 100)"
        >
          <span
            v-for="(l, index) in m.content"
            :key="index"
            class="item-menu"
            @click="routerGo(l.link)"
          >
            <v-icon class="ma-2" v-if="l.icon">{{ l.icon }}</v-icon>
            {{ l.text }}</span
          >
        </div>
      </div>

      <span @click="handleLogout" class="link-home">
        <v-icon class="mx-2">mdi-logout</v-icon>
        Sair
      </span>
      <p class="white-text">
        Versão: {{ version }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menus: {
      type: Array,
      default: null,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      panels: null,
      version: process.env.VUE_APP_VERSION
    };
  },
  methods: {
    handleLogout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    closeAll() {
      this.panels = null;
    },
    routerGo(link) {
      this.$router.push(link);
      this.$emit("going");
    },
  },
};
</script>

<style lang="scss" scoped>
.item-menu {
  color: rgb(43, 45, 47);
  padding: 0 10px;
  background-color: #229466;
  display: inline-block;
  margin: 10px;
  text-decoration: none;
  box-shadow: 2px 2px 2px;
  transition: 0.5s;
  border-radius: 5px;
  cursor: pointer;
}
.item-menu:hover {
  background-color: rgb(43, 45, 47);
  color: #229466;
}
.link-home {
  color: rgb(43, 45, 47);
  padding-left: 20px;
  background-color: #10b97d;
  width: 100%;
  display: flex;
  margin-top: 10px;
  text-decoration: none;
  border-radius: 1px;
  cursor: pointer;
  height: 50px;
  margin: 5px 0;
  align-items: center;
  transition: 0.5s;
}
.link-home:hover {
  background-color: rgb(43, 45, 47);
  color: #229466;
  box-shadow: 0 0 10px #229466 inset;
}

.header-menu {
  font-size: 18px;
  font-weight: bold;
  margin-left: 20px;
  position: relative;
  display: inline-block;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 140%;
  //   height: 100%;
  //   border-bottom: solid 2px rgb(22, 22, 22);
  //   left: 0;
  //   top: 0;
  // animation: title-animation 2s;
  // animation-fill-mode: backwards;
  // animation-iteration-count: infinite;
  // }
}

.white-text {
  color: #fff !important;
  padding-left: 5px;
}

@keyframes title-animation {
  0% {
    width: 0%;
  }
  50% {
    width: 140%;
  }
  100% {
    width: 0%;
  }
}

@media (max-width: 400px) {
  .item-menu {
    width: 120px;
  }
}
</style>