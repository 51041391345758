<template>
  <div>
    <div>
      <img
        :class="classImg"
        @click="handleMenu"
        src="@/assets/logo_sem_fundo.png"
        alt="Logo Latina"
      />
    </div>
    <div :class="classMenu">
      <div class="menu-links">
        <link-component
          ref="link"
          :enabled="extended"
          :class="classMenu"
          @going="handleMenu"
          :menus="menus"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LinkComponent from "./LinkComponent";

export default {
  components: {
    LinkComponent,
  },
  mounted() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "F2") {
        this.handleMenu();
      }
    });
  },
  data() {
    return {
      classMenu: "app-menu",
      classImg: "",
      extended: false,
      menus: [
        {
          name: "Cadastros",
          rule: "user",
          content: [
            { text: "Insumos", link: "/insumos", icon: "mdi-gas-station" },
            { text: "Veículos", link: "/veiculos", icon: "mdi-truck" },
            {
              text: "Motoristas",
              link: "/motoristas",
              icon: "mdi-card-account-details",
            },
            { text: "Clientes", link: "/clientes", icon: "mdi-account-group" },
            { text: "Cidades", link: "/cidades", icon: "mdi-map" },
            {
              text: "Tipos Ocorrências",
              link: "/tipos-ocorrencias",
              icon: "mdi-alert",
            },
          ],
        },
        {
          name: "CTe",
          rule: "user",
          content: [
            { text: "Listar", link: "/ctes", icon: "mdi-format-list-bulleted" },
            { text: "Novo", link: "/adicionar-cte", icon: "mdi-plus" },
            { text: "Pesquisar", link: "/consulta-cte", icon: "mdi-magnify" },
            {
              text: "Atrasados na chegada",
              link: "/chegada-atrasados",
              icon: "mdi-clock",
            },
          ],
        },
        {
          name: "Entregas",
          rule: "user",
          content: [
            {
              text: "Pesquisar CTe",
              link: "/consulta-cte",
              icon: "mdi-magnify",
            },
            {
              text: "Romaneios",
              link: "/romaneios-completo",
              icon: "mdi-truck",
            },
            // { text: 'Romaneios (antigo)', link: '/romaneios', icon: 'mdi-moped-outline' },
          ],
        },
        {
          name: "Administração",
          rule: "manager",
          content: [
            // { text: 'Regras', link: '/regras-comissao', icon: 'mdi-puzzle' },
            { text: "Comissões", link: "/comissoes", icon: "mdi-currency-usd" },
            { text: "Sem Taxa", link: "/ctes-sem-taxa", icon: "mdi-filter" },
            { text: "Parceiras", link: "/parceiras", icon: "mdi-handshake" },
            {
              text: "Usuários",
              link: "/usuarios",
              icon: "mdi-account-multiple-plus",
            },
          ],
        },
        {
          name: "Coletas",
          rule: "user",
          content: [
            { text: "Regras", link: "/regras-coletas", icon: "mdi-puzzle" },
            {
              text: "Regra-cliente",
              link: "/regras-clientes",
              icon: "mdi-puzzle",
            },
            { text: "Coletas", link: "/coletas", icon: "mdi-truck" },
          ],
        },
      ],
    };
  },
  methods: {
    handleMenu() {
      //this.$refs.link.closeAll();
      this.extended = !this.extended;
      if (this.extended) {
        this.classMenu = "app-menu anim";
        this.classImg = "spin-effect";
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.classMenu = "app-menu anim-off";
        this.classImg = "spin-effect-off";
      }
    },
    handleGoTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.app-menu {
  width: 100%;
  background: rgba(43, 45, 47, 0.9);
  box-shadow: 0 0 10px;
  position: absolute;
  top: 0;
  z-index: 99998;
  /*right: -100%;*/
  height: 100%;
  /*opacity: 0;*/
  transform: scale(0);
}
.anim {
  animation-name: effect;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
.anim-off {
  animation-name: effect-off;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.spin-effect {
  /*animation-name: spin;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;*/
}

.spin-effect-off {
  /*animation-name: spin-off;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;*/
}

img {
  top: 0;
  height: 70px;
  float: left;
  margin-right: 20px;
  cursor: pointer;
  box-shadow: 0 0 10px;
  border-radius: 50%;
  transition: 0.5s;
  background: #fff;
  z-index: 99999;
  position: absolute;
  opacity: 0.1;
  transition: 1s;
  right: 0;
}

img:hover {
  opacity: 1;
}

.menu-links {
  position: absolute;
  width: 100%;
}

@keyframes effect {
  from {
    /*right: -100%;*/
    /*opacity: 0;*/
    transform: scale(0);
  }
  to {
    /*right: 0px;*/
    /*opacity: 1;*/
    transform: scale(1);
  }
}
@keyframes effect-off {
  from {
    /*right: 0px;*/
    /*opacity: 1;*/
    transform: scale(1);
  }
  to {
    /*right: -100%;*/
    /*opacity: 0;*/
    transform: scale(0);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin-off {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
</style>
