import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';

let colors = localStorage.getItem('colors');
colors = JSON.parse(colors);

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		token: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null,
		user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
		current_cnpj: '',
		dateDeliveries: {
			dateFrom: null,
			dateTo: null
		},
		ctesPerPage: 20,
		ctesSearch: '',
		colors: {
			ok: colors && colors.ok ? colors.ok : '#91E0A4FF',
			atrasadas: colors && colors.atrasadas ? colors.atrasadas : '#EEA1A1FF',
			retido: colors && colors.retido ? colors.retido : '#F7D4FAFF',
			hoje: colors && colors.hoje ? colors.hoje : '#EEF3A5FF',
			ocorrencia: colors && colors.ocorrencia ? colors.ocorrencia : '#FFA74EFF',
			rota: colors && colors.rota ? colors.rota : '#A5D1F3FF'
		},
		selectedMonth: parseInt(new Date().toISOString().slice(5, 7)),
		selectedYear: parseInt(new Date().toISOString().slice(0, 4)),
		account: null,
		partners: null,
		tableSize: localStorage.getItem('table_size') ? JSON.parse(localStorage.getItem('table_size')) : 50
	},
	mutations,
	actions,
	modules: {}
});
