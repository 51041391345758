<template>
  <div>
    <v-progress-circular size="100" indeterminate v-if="!partner" />
    <v-form v-if="partner" ref="formCreatePartner" @submit.prevent="handleSubmit">

      <v-text-field
        label="Nome"
        v-model="partner.name"
        :rules="[v => !! v || 'Este campo é obrigatório']"
      ></v-text-field>

      <v-btn 
        color="success" 
        type="submit"
        :loading="saving"
      >
        Salvar
      </v-btn>

    </v-form>
  </div>
</template>

<script>
  export default {
    props: {
      partner: {
        type: Object,
        default() {
          return {
            name: null
          }
        }
      },
      saving: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleSubmit() {
        if(this.$refs.formCreatePartner.validate()) {
          this.$emit('submited', this.partner);
        }
      }
    },
  }
</script>