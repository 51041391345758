var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex mb-2"},[_c('h3',[_vm._v("Regras (Coletas)")]),_c('v-btn',{attrs:{"color":"success","fab":"","small":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"disable-pagination":"","hide-default-footer":"","disable-sort":"","disable-filtering":"","loading":_vm.loading,"item-class":function () { return 'cursor-pointer'; }},on:{"click:row":function($event){return _vm.openEdit($event)}},scopedSlots:_vm._u([{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.all_cities ? 'Todas' : item.cities.name))])]}},{key:"item.icms",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.icms ? 'Sim' : 'Não'))])]}},{key:"item.toll_discount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.toll_discount ? 'Sim' : 'Não'))])]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.percentage)+"%")])]}},{key:"item.min_freight",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$functions.moneyFormat(item.min_freight)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('v-icon',{attrs:{"color":"error","title":"Excluir rule"},on:{"click":function ($event) { return _vm.deleteRule($event, item.id); }}},[_vm._v("mdi-delete")])],1)]}}],null,true)}),(_vm.show)?_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.rule && _vm.rule.id ? ("Editar regra " + (_vm.rule.name ? _vm.rule.name : '')) : 'Nova regra')+" ")]),_c('v-card-text',[_c('v-form',{ref:"formManageRule",attrs:{"disabled":_vm.loading || _vm.saving},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Todas as cidades"},model:{value:(_vm.rule.all_cities),callback:function ($$v) {_vm.$set(_vm.rule, "all_cities", $$v)},expression:"rule.all_cities"}})],1),_c('v-col',[(!_vm.rule.all_cities)?_c('v-select',{attrs:{"label":"Cidade","items":_vm.cities,"item-text":"name","item-value":"id","rules":[
                  function (v) { return !!v || 'Obrigatório'; }
                ]},model:{value:(_vm.rule.city_id),callback:function ($$v) {_vm.$set(_vm.rule, "city_id", $$v)},expression:"rule.city_id"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Desconto ICMS"},model:{value:(_vm.rule.icms),callback:function ($$v) {_vm.$set(_vm.rule, "icms", $$v)},expression:"rule.icms"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Desconto Pedágio"},model:{value:(_vm.rule.toll_discount),callback:function ($$v) {_vm.$set(_vm.rule, "toll_discount", $$v)},expression:"rule.toll_discount"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Porcentagem comissão","rules":[
                  function (v) { return !!v || 'Obrigatório'; }
                ]},model:{value:(_vm.rule.percentage),callback:function ($$v) {_vm.$set(_vm.rule, "percentage", $$v)},expression:"rule.percentage"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Frete mínimo","rules":[
                  function (v) { return !!v || 'Obrigatório'; }
                ]},model:{value:(_vm.rule.min_freight),callback:function ($$v) {_vm.$set(_vm.rule, "min_freight", $$v)},expression:"rule.min_freight"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[(_vm.loading || _vm.saving)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.loading || _vm.saving},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","text":"","disabled":_vm.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }