<template>
  <v-app>
    <quick-menu
      v-if="this.$store.state.token != null"
      ref="quickMenuComponent"
    />
    <AppMenu v-if="this.$store.state.token != null" />
    <v-container fluid>
      <div v-if="isSearching">
        <v-dialog max-width="800px" v-model="isSearching">
          <global-search @close="isSearching = false" />
        </v-dialog>
      </div>
      <router-view></router-view>
    </v-container>
  </v-app>
</template>

<script>
import GlobalSearch from "@/components/GlobalSearch";
import AppMenu from "./components/menu/AppMenu";
import QuickMenu from "./components/QuickMenu.vue";
export default {
  components: {
    AppMenu,
    GlobalSearch,
    QuickMenu,
  },

  data: () => ({
    isSearching: false,
    quickMenu: false,
  }),

  created() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "F4") {
        this.$router.push("/consulta-cte");
      }
    });

    window.addEventListener("keydown", (e) => {
      if (e.key == "F6") {
        e.preventDefault();

        if (this.$route.path !== "/") {
          this.$router.push("/");
        }
      }
    });
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  background: #eee;
}
.container {
  height: 100%;
}
.text-error {
  background-color: rgb(255, 136, 136) !important;
}
.text-warning {
  background-color: rgb(255, 227, 102) !important;
}
.text-info {
  background-color: rgb(133, 231, 151) !important;
}
.text-primary {
  background-color: rgb(139, 192, 228) !important;
}
.text-pink {
  background-color: #dec1d8 !important;
}

.action-button {
  background: rgb(224, 219, 210);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  box-shadow: -1px -1px 3px white, 1px 1px 3px #454545;
  &:hover {
    box-shadow: inset -1px -1px 3px white, inset 1px 1px 3px #454545;
  }
}

.green-row {
  background: #bbe6bb !important;
  color: #0c660c;
}

.red-row {
  background: #e6c4c4 !important;
  color: #550c0c;
}

.v-icon--disabled {
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

.black-hover:hover {
  /* background-color: #505050!important;
    color: white!important; */
  opacity: 0.6;
  /* cursor: pointer; */
}

.cursor-pointer {
  cursor: pointer !important;
}

.flex {
  display: flex;
  gap: 1em;
  align-items: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 30px;
  background: #ff3939;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 5px black;
  color: white;
}

.unselectable {
  user-select: none;
}

.focus-none::after,
.focus-none-complete::after {
  opacity: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px rgba(189, 228, 221, 0) inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #2dab58 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #344;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e7070;
}

* {
  scrollbar-color: #344 #fff;
  scrollbar-width: thin;
}
</style>
