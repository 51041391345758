import authorization from '@/modules/auth'
import store from '@/store'

export default [
	{
		path: '/regras-coletas',
		name: 'collectRules.index',
		component: () => import(/* webpackChunkName: "collectRules.index" */ './pages/Index.vue'),
		beforeEnter: (to, from, next) => {
      if(authorization.isAuthorized('manager')) {
        return next()
      } else {
        store.dispatch('logout');
      }
    }
	},
	{
		path: '/regras-coletas/adicionar/:id?',
		name: 'collectRules.create',
		component: () => import(/* webpackChunkName: "collectRules.create" */ './pages/Create.vue'),
		beforeEnter: (to, from, next) => {
      if(authorization.isAuthorized('manager')) {
        return next()
      } else {
        store.dispatch('logout');
      }
    }
	}
]