<template>
  <div>
    <v-btn
      color="secondary"
      @click="handleSendEmails"
    >
      {{ text }}
    </v-btn>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        text: 'Enviar e-mails',
      }
    },
    methods: {
      async handleSendEmails() {
        if(!confirm('Deseja enviar o email para todas as parceiras?')) {
          return;
        }

        this.text = 'Enviando...';

        try {
          if(await this.$http.post(`api/send-emails`)) {
            this.$toast.success('Emails Enviados');
          }
        } catch(err) {
          console.log(err);
          this.$toast.error('Erro ao enviar');
        }

        this.text = 'Enviar e-mails';
        
      }
    }
  }
</script>