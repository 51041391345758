export default [
	{
		path: '/clientes',
		name: 'Customers.index',
		component: () => import(/* webpackChunkName: "Customers.index" */ './pages/Index.vue')
	},
	{
		path: '/novo-cliente',
		name: 'Customers.create',
	  component: () => import(/* webpackChunkName: "Customers.create" */ './pages/Create.vue')
	},
	{
		path: '/clientes/:id',
		name: 'Customers.edit',
	  component: () => import(/* webpackChunkName: "Customers.edit" */ './pages/Edit.vue')
	},
	{
		path: '/clientes-com-tde',
		name: 'customers.tde',
	  component: () => import(/* webpackChunkName: "customers.tde" */ './pages/HasTde.vue')
	}
]