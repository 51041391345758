import Index from './pages/Index.vue';
import Create from './pages/Create.vue';

export default [
	{
		path: '/regras-clientes',
		name: 'customer-rules.index',
		component: Index
	},
	{
		path: '/regras-clientes/adicionar',
		name: 'customer-rules.create',
		component: Create
	}
]