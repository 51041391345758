import Index from './pages/Index.vue';
import Create from './pages/Create.vue';
import Edit from './pages/Edit.vue';

import authorization from '@/modules/auth';
import store from '@/store'

export default [
  {
    path: '/parceiras',
    name: 'partners.index',
    component: Index,
    beforeEnter: (to, from, next) => {
      if(authorization.isAuthorized('manager')) {
        return next()
      } else {
        store.dispatch('logout');
      }
    }
  },
  {
    path: '/parceiras/criar',
    name: 'partners.create',
    component: Create,
    beforeEnter: (to, from, next) => {
      if(authorization.isAuthorized('manager')) {
        return next()
      } else {
        store.dispatch('logout');
      }
    }
  },
  {
    path: '/parceiras/editar/:id',
    name: 'partners.edit',
    component: Edit,
    beforeEnter: (to, from, next) => {
      if(authorization.isAuthorized('manager')) {
        return next()
      } else {
        store.dispatch('logout');
      }
    }
  },
]