<template>
  <div>
    <h1>Parceiras</h1>

    <SendEmails />

    <v-btn
      fab
      small
      color="success"
      class="ma-2"
      @click="$router.push({ name: 'partners.create' })"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-data-table
      :items="partners"
      :headers="headers"
      disable-pagination
      hide-default-footer
      dense
      :loading="loading"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon 
          @click="$router.push({ name: 'partners.edit', params: { id: item.id } })" 
          title="Editar parceira" 
          color="warning"
        >
          mdi-pencil
        </v-icon>

        <v-icon title="Desativar parceira" disabled color="error">
          mdi-cancel
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import SendEmails from '../components/SendEmails.vue';

  export default {
    components: {
      SendEmails,
    },
    mounted () {
      this.getPartners();
    },
    data() {
      return {
        partners: [],
        headers: [
          {text: 'ID', value: 'id'},
          {text: 'Nome', value: 'name'},
          {text: 'Ações', value: 'actions'},
        ],
        loading: false,
      }
    },
    methods: {
      getPartners() {
        this.loading = true;
        this.$http.get(`api/partners`)
          .then(({data}) => {
            this.loading = false;
            this.partners = data;
          })
          .catch(err => {
            this.loading = false;
            console.log(err);
            this.$toast.error('Erro ao carregar parceiras');
          });
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>