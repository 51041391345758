import store from '../store'
import router from '../router'
export default {
  logout() {
    store.dispatch('set_token', null),
    store.dispatch('set_user', null),
    router.push('/login')
  },
  isAuthorized(role) {
    const user = store.state.user
    if(user) {
      if(role == 'superAdmin') {
        if(user.role == 'superAdmin') {
          return true
        }
      } else if(role == 'admin') {
        if(user.role == 'superAdmin' || user.role == 'admin') {
          return true
        }
      } else if(role == 'manager') {
        if(user.role == 'superAdmin' || user.role == 'admin' || user.role == 'manager') {
          return true
        }
      } else if(role == 'user') {
        if(user.role == 'superAdmin' || user.role == 'admin' || user.role == 'manager' || user.role == 'user') {
          return true
        }
      }
    } else {
      return false
    }
  }
}