<template>
  <div style="display: inline-block">
    <v-btn color="primary" class="mx-1" @click="print(false)">
      <v-icon>mdi-printer</v-icon>
      <span class="ml-2">Imprimir</span>
    </v-btn>
    <v-btn color="secondary" class="mx-1" @click="print(true)">
      <v-icon>mdi-download</v-icon>
      <span class="ml-2">Baixar</span>
    </v-btn>
  </div>
</template>

<script>
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import logo from "../../../assets/logo_sem_fundo.png";

export default {
  props: [
    "tda",
    "tde",
    "fees",
    "discharge",
    "palletization",
    "total",
    "commission",
    "ctes",
    "params",
    "collects",
  ],
  computed: {
    freightPaidIndex() {
      let index = 0;
      let indexes = [];
      this.commission.commission_ctes.map(({ cte }) => {
        if (cte.freight_paid) {
          indexes.push(index);
        }
        index++;
      });

      return indexes;
    },
    freight_paid() {
      let ctes = [];
      this.commission.commission_ctes.forEach((commission) => {
        if (commission.cte.freight_paid) {
          ctes.push(commission);
        }
      });

      return ctes;
    },
  },
  methods: {
    handleDeliveryDate(cte) {
      return cte.delivery_date ?? cte.delivered;
    },
    deliveriesData() {
      let deliveriesData = [];

      this.commission.commission_ctes.map((delivery) => {
        const newDelivery = [
          moment(this.handleDeliveryDate(delivery.cte)).format("DD/MM/YYYY"),
          delivery.cte.number,
          this.$functions.cutName(delivery.cte.nf, 8),
          this.$functions.cutName(delivery.cte.recipients.name, 20),
          this.$functions.moneyFormat(delivery.cte.tda || 0),
          this.$functions.moneyFormat(delivery.cte.tde || 0),
          this.$functions.moneyFormat(delivery.cte.fees || 0),
          this.$functions.moneyFormat(delivery.cte.discharge || 0),
          this.$functions.moneyFormat(
            delivery.cte.palletizations[0]?.value || 0
          ),
          this.$functions.moneyFormat(delivery.cte.freight_value),
          this.$functions.moneyFormat(
            delivery.cte.commission_value || delivery.cte_commission
          ),
        ];

        deliveriesData.push(newDelivery);
      });

      return deliveriesData;
    },
    collectsData() {
      let collectsData = [];

      this.commission.commission_collects.map((commission) => {
        const newCollect = [
          moment(commission.collect.date).format("DD/MM/YYYY"),
          commission.collect.cte,
          this.$functions.cutName(commission.collect.nf, 15),
          this.$functions.cutName(commission.collect.sender, 20),
          this.$functions.cutName(commission.collect.recipient, 20),
          this.$functions.moneyFormat(commission.collect.freight_value),
          this.$functions.moneyFormat(
            commission.collect.commission_value ||
              commission.collect_commission_value
          ),
        ];

        collectsData.push(newCollect);
      });

      return collectsData;
    },
    deliveriesTotal() {
      return [
        "",
        "",
        "",
        "",
        this.$functions.moneyFormat(this.commission.total_tda || 0),
        this.$functions.moneyFormat(this.commission.total_tde || 0),
        this.$functions.moneyFormat(this.commission.total_fees || 0),
        this.$functions.moneyFormat(this.commission.total_dischage || 0),
        this.$functions.moneyFormat(this.commission.total_palletization || 0),
        this.$functions.moneyFormat(this.commission.total_deliveries_freight),
        this.$functions.moneyFormat(
          this.commission.total_deliveries_commission
        ),
      ];
    },
    collectsTotal() {
      return [
        "",
        "",
        "",
        "",
        "",
        this.$functions.moneyFormat(this.commission.total_collects_freight),
        this.$functions.moneyFormat(this.commission.total_collects_commission),
      ];
    },
    paidData() {
      let ctesPaidData = [];

      this.freight_paid.map((cte_paid) => {
        let newCtePaid = [
          moment(cte_paid.cte.delivered).format("DD/MM/YYYY"),
          cte_paid.cte.number,
          this.$functions.cutName(cte_paid.cte.nf),
          this.$functions.cutName(cte_paid.cte.recipients.name, 30),
          this.$functions.moneyFormat(cte_paid.cte.freight_value),
          this.$functions.moneyFormat(
            cte_paid.cte.commission_value || cte_paid.cte_commission
          ),
        ];

        ctesPaidData.push(newCtePaid);
      });

      return ctesPaidData;
    },
    print(download) {
      const doc = new jsPDF({ orientation: "landscape" });
      const partnerLogo = new Image();

      doc.addImage(logo, 70, 0, 70, 70);

      if (this.commission.partner.image) {
        try {
          partnerLogo.src = require("@/assets/" +
            this.commission.partner.image);
          doc.addImage(partnerLogo, 150, 10);
        } catch (error) {
          console.log(error);
        }
      }

      doc.setFontSize(22);
      doc.setFont("helvetica", "bold");
      doc.text(
        `Fechamento ${this.commission.partner.name} | Referente ${moment(
          this.commission.initial_date
        ).format("DD/MM/YYYY")} à ${moment(this.commission.final_date).format(
          "DD/MM/YYYY"
        )}`,
        20,
        60
      );

      doc.setFontSize(20);
      doc.text("Resumo", 20, 80);

      doc.autoTable({
        body: [
          [
            "Total de Entregas | Frete",
            this.$functions.moneyFormat(
              this.commission.total_deliveries_freight
            ),
          ],
          [
            "Total de Entregas | Comissão",
            this.$functions.moneyFormat(
              this.commission.total_deliveries_commission
            ),
          ],
          [
            "Total de TDA",
            this.$functions.moneyFormat(this.commission.total_tda),
          ],
          [
            "Total de TDE",
            this.$functions.moneyFormat(this.commission.total_tde),
          ],
          [
            "Total de Taxas",
            this.$functions.moneyFormat(this.commission.total_fees),
          ],
          [
            "Total de Descarga",
            this.$functions.moneyFormat(this.commission.total_dischage),
          ],
          [
            "Total de Paletização",
            this.$functions.moneyFormat(this.commission.total_palletization),
          ],
          [
            "Total de Coletas | Frete",
            this.$functions.moneyFormat(this.commission.total_collects_freight),
          ],
          [
            "Total de Coletas | Comissão",
            this.$functions.moneyFormat(
              this.commission.total_collects_commission
            ),
          ],
        ],
        foot: [
          [
            "Total da Geral Comissão",
            this.$functions.moneyFormat(this.commission.total_commission),
          ],
        ],
        startY: 90,
        styles: { fontSize: 16 },
      });

      doc.addPage("A4", "landscape");

      doc.setFont("helvetica", "bold");
      doc.setFontSize(20);
      doc.text("Detalhes", 20, 20);

      doc.setFontSize(16);
      doc.text("Entregas", 20, 35);

      doc.autoTable({
        head: [
          [
            "Data",
            "CTe",
            "NFe",
            "Destinatário",
            "TDA",
            "TDE",
            "Taxas",
            "Descarga",
            "Paletização",
            "Frete",
            "Comissão",
          ],
        ],
        willDrawCell: (data) => {
          if (this.freightPaidIndex.some((i) => i === data.row.index)) {
            doc.setFillColor(239, 154, 154);
          }
        },
        styles: { cellPadding: 0.5, fontSize: 8 },
        columnStyles: { 3: { cellWidth: 50 } },
        body: [...this.deliveriesData()],
        foot: [this.deliveriesTotal()],
        theme: "grid",
        startY: 45,
        showFoot: "lastPage",
      });

      let finalY = doc.lastAutoTable.finalY;
      doc.setFont("helvetica", "bold");

      if (this.commission.commission_collects.length > 0) {
        doc.text("Coletas", 20, finalY + 15);

        doc.autoTable({
          head: [
            [
              "Coleta",
              "CTe",
              "NFe",
              "Remetente",
              "Destinatário",
              "Frete",
              "Comissão",
            ],
          ],
          columnStyles: { 3: { cellWidth: 50 }, 4: { cellWidth: 50 } },
          body: [...this.collectsData()],
          foot: [this.collectsTotal()],
          theme: "grid",
          startY: finalY + 20,
          showFoot: "lastPage",
          styles: { cellPadding: 0.5, fontSize: 8 },
        });
      }

      let finalCollects = doc.lastAutoTable.finalY;
      doc.setFont("helvetica", "bold");

      if (this.freight_paid.length > 0) {
        doc.text("Frete à vista", 20, finalCollects + 15);

        doc.autoTable({
          head: [
            ["Entrega", "CTe", "NFe", "Destinatário", "Frete", "Comissão"],
          ],
          columnStyles: { 3: { cellWidth: 50 } },
          body: [...this.paidData()],
          theme: "grid",
          startY: finalCollects + 20,
          styles: { cellPadding: 0.5, fontSize: 8 },
        });
      }

      if (download) {
        doc.save(
          `fechamento-${this.commission.partner.name}-${moment(
            this.commission.initial_date
          ).format("DD/MM/YYYY")}-${moment(this.commission.final_date).format(
            "DD/MM/YYYY"
          )}`
        );
        return;
      }

      doc.setProperties({
        title: `fechamento-${this.commission.partner.name}-${moment(
          this.commission.initial_date
        ).format("DD/MM/YYYY")}-${moment(this.commission.final_date).format(
          "DD/MM/YYYY"
        )}`,
      });
      doc.output("dataurlnewwindow");
    },
  },
};
</script>
