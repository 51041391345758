export default [
  {
    path: "/feriados",
    name: "holidays.index",
    component: () =>
      import(/* webpackChunkName: "holidays.index" */ "./pages/Index.vue"),
  },
  {
    path: "/feriados/novo",
    name: "holidays.create",
    component: () =>
      import(/* webpackChunkName: "holidays.create" */ "./pages/Create.vue"),
  },
];
