export default [
  {
    path: '/regras-clientes-coletas',
    name: 'CollectPartnerRules.index',
    component: () => import(/* webpackChunkName: "CollectPartnerRules.index" */ './pages/Index.vue'),
  },
  {
    path: '/nova-regra-cliente-coleta',
    name: 'CollectPartnerRules.create',
    component: () => import(/* webpackChunkName: "CollectPartnerRules.create" */ './pages/Create.vue'),
  },
]