<template>
	<div>
		<v-btn @click="addingGroup = true" class="ma-2" color="success" fab small>
			<v-icon>mdi-plus</v-icon>
		</v-btn>
		<v-data-table
			:items="groups"
			:headers="[
				{text: 'Valor', value: 'value'},
				{text: 'Ações', value: 'actions'}
			]"
			dense
			disable-pagination
			hide-default-footer
			:loading="loading"
		>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon :disabled="loading || saving || deleting" color="error" @click="handleDelete(item.id)">mdi-delete</v-icon>
			</template>
		</v-data-table>

		<v-dialog v-model="addingGroup">
			<v-card>
				<v-card-title>Adicionar Agrupamento</v-card-title>
				<v-card-text>
					<v-form @submit.prevent="handleSubmit" ref="formCreateCommissionGroup">
						<v-text-field
							label="Critério do grupo"
							v-model="group.value"
							:rules="[v => !! v || 'Este campo é obrigatório']"
						></v-text-field>

						<v-btn :loading="saving" type="submit" color="success">Salvar</v-btn>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		props: [
			'partner_id',
		],
		mounted() {
			this.getGroups();
		},
		data() {
			return {
				group: {
					partner_id: this.partner_id,
					value: null
				},
				groups: [],
				addingGroup: false,
				saving: false,
				loading: false,
				deleting: false,
			}
		},
		methods: {
			handleDelete(id) {
				this.deleting = true;
				if(confirm('Tem certeza que deseja excluir este agrupamento?')) {
					this.$http.delete(`api/commission-group/${id}`)
						.then(() => {
							this.deleting = false;
							this.$toast.success('Removido com sucesso');
							this.getGroups();
						}).catch(err => {
							this.deleting = false;
							this.$toast.error('Erro ao remover');
						});
				}
			},
			handleSubmit() {
				if(this.$refs.formCreateCommissionGroup.validate()) {
					this.saving = true;
					this.$http.post(`api/commission-group`, this.group)
						.then(() => {
							this.saving = false;
							this.$toast.success('Salvo com sucesso');
							this.addingGroup = false;
							this.getGroups();
							this.$refs.formCreateCommissionGroup.reset();
						}).catch(err => {
							console.log(err);
							this.$toast.error('Erro ao salvar');
							this.saving = false;
						});
				}
			},
			getGroups() {
				this.loading = true;
				this.$http.get(`api/commission-group?partner_id=${this.partner_id}`)
					.then(({data}) => {
						this.loading = false;
						this.groups = data;
					}).catch(err => {
						this.loading = false;
						this.$toast.error('Erro ao carregar agrupamentos');
					});
			}
		}
	}
</script>
