import Vue from "vue";
import VueRouter from "vue-router";
import auth from "../views/auth/routes";
import deliveries from "../views/deliveries/routes";
import ctes from "../views/ctes/routes";
import regions from "../views/regions/routes";
import cities from "../views/cities/routes";
import customers from "../views/customers/routes";
import fuels from "../views/fuels/routes";
import vehicles from "../views/vehicles/routes";
import drivers from "../views/drivers/routes";
import collects from "../views/collects/routes";
import occurrences from "../views/occurrences/routes";
import commissionRules from "../views/commissionRules/routes";
import collectRules from "../views/collectRules/routes";
import commissions from "../views/commissions/routes";
import collectPartnerRules from "../views/collectPartnerRules/routes";
import partners from "../views/partners/routes";
import holidays from "../views/holidays/routes";
import users from "../views/users/routes";
import customerRules from "../views/customerRules/routes";
import authorization from "../modules/auth";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/ctes/pages/Index.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    beforeEnter: (to, from, next) => {
      if (authorization.isAuthorized("superAdmin")) {
        return next();
      } else {
        return next({ name: "Login" });
      }
    },
  },
  {
    path: "/configuracoes",
    name: "Configurations",
    component: () =>
      import(
        /* webpackChunkName: "configurations" */ "../views/configurations/Index.vue"
      ),
  },
  auth,
  ...deliveries,
  ...ctes,
  regions,
  ...cities,
  ...customers,
  ...fuels,
  ...vehicles,
  ...drivers,
  ...occurrences,
  ...commissionRules,
  ...commissions,
  ...collects,
  ...collectRules,
  ...collectPartnerRules,
  ...customerRules,
  ...partners,
  ...holidays,
  ...users,
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.path !== "/login") {
    if (store.state.token != null) {
      next();
    } else {
      next({ name: "Login" });
    }
  }
  if (to.path === next.path) {
    next(from);
  }
  next();
});

export default router;
