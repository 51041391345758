export default [
  {
    path: "/usuarios",
    name: "users.index",
    component: () =>
      import(/* webpackChunkName: "users.index" */ "./pages/Index.vue"),
  },
  {
    path: "/usuarios/novo",
    name: "users.create",
    component: () =>
      import(/* webpackChunkName: "users.create" */ "./pages/Create.vue"),
  },
  {
    path: "/usuarios/:id/editar",
    name: "users.edit",
    component: () =>
      import(/* webpackChunkName: "users.edit" */ "./pages/Edit.vue"),
  },
];
