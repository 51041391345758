<template>
  <div>
    <h1>Fechar comissão</h1>
    <v-btn
      color="error"
      fab
      small
      @click="$router.back()"
      class="ma-2"
    >
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <v-btn
      color="primary"
      fab
      small
      @click="$router.push({ name: 'commissions.list' })"
      class="ma-2"
      title="Listar comissões"
    >
      <v-icon>mdi-clipboard-list</v-icon>
    </v-btn>

    <v-btn
      fab
      small
      @click="goToRulesManagement"
      class="ma-2"
      title="Gerenciar regras"
    >
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <div class="d-flex flex-wrap commission-block">
      <v-select
        label="Parceira"
        :items="partners"
        item-value="id"
        item-text="name"
        class="mx-1"
        v-model="partner"
        :disabled="loading"
      ></v-select>
      <v-text-field
        type="date"
        label="Data Inicio"
        class="mx-1"
        v-model="dateFrom"
        :disabled="loading"
      ></v-text-field>
      <v-text-field
        type="date"
        label="Data Fim"
        class="mx-1"
        v-model="dateTo"
        :disabled="loading"
      ></v-text-field>
      <v-btn 
        class="mx-2" 
        color="success" 
        @click="load()"
        :loading="loading"
        :disabled="loading || !partner || !dateFrom || !dateTo"
        fab
        small
        title="Gerar"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn
      >
    </div>

    <h2 v-if="deliveries.ctes && deliveries.ctes.length > 0">Entregas</h2>
    <v-data-table
      v-if="deliveries.ctes && deliveries.ctes.length > 0"
      :items="deliveries.ctes"
      :headers="headers"
      dense
      hide-default-footer
      disable-pagination
      :item-class="rowClass"
    >
      <template v-slot:[`item.delivered`]="{ item }">
        <span v-html="handleShowDeliveryDate(item)"></span>
      </template>
      
      <template v-slot:[`item.number`]="{ item }">
        <v-tooltip right color="#000">
          <template v-slot:activator="{ on, attrs }">
            <a 
              v-bind="attrs"
              v-on="on"
              target="_blank" 
              :href="`/#/editar-cte/${item.id}`"
            >{{
              item.number
            }}</a>
          </template>
          <div>
            <p>Outras informações:</p>
            <ul>
              <li v-if="item.senders">Remetente: {{ item.senders.name }}</li>
              <li>Taxas: {{ $functions.moneyFormat(item.fees) }}</li>
              <li>Descarga: {{ $functions.moneyFormat(item.discharge) }}</li>
              <li>Paletização: {{ $functions.moneyFormat(item.palletization) }}</li>
              <li>Pedágio: {{ $functions.moneyFormat(item.toll) }}</li>
            </ul>
          </div>
        </v-tooltip>
      </template>

      <template v-slot:[`item.nf`]="{ item }">
        <span :title="item.nf">{{ $functions.cutName(item.nf, 10) }}</span>
      </template>

      <template v-slot:[`item.recipients.name`]="{ item }">
        <a
          :href="`/#/clientes/${item.recipients.id}`"
          target="_blank"
          :title="item.recipients.name"
        >
          {{ $functions.cutName(item.recipients.name, 14) }}
        </a>
      </template>

      <template v-slot:[`item.recipients.cities.name`]="{ item }">
        <a
          :href="`/#/editar-cidade/${item.recipients.cities.id}`"
          target="_blank"
          :title="item.recipients.cities.name"
        >
          {{ $functions.cutName(item.recipients.cities.name, 14) }}
        </a>
      </template>

      <template v-slot:[`item.tda`]="{ item }">
        <span :title="handleTdaTitle(item)" :class="handleTdaClass(item)">{{
          $functions.moneyFormat(item.tda)
        }}</span>
      </template>

      <template v-slot:[`item.tde`]="{ item }">
        <span :title="handleTdeTitle(item)" :class="handleTdeClass(item)">
          {{ $functions.moneyFormat(item.tde) }}
        </span
        >
      </template>

      <template v-slot:[`item.fees`]="{ item }">
        <span>{{ $functions.moneyFormat(item.fees) }}</span>
      </template>

      <template v-slot:[`item.palletization`]="{ item }">
        <span>{{ $functions.moneyFormat(item.palletization) }}</span>
      </template>

      <template v-slot:[`item.freight_value`]="{ item }">
        <span 
          :class="item.freight_paid ? 'purple--text' : ''"
          :title="item.freight_paid ? 'Frete pago à vista' : ''"
        >{{ $functions.moneyFormat(item.freight_value) }}</span>
      </template>

      <template v-slot:[`item.commission`]="{ item }">
        <span 
          :class="item.commission_value ? 'red--text' : ''"
          :title="item.commission_value ? 'Comissão definida manualmente' : ''"
        >
          {{ item.commission_value ? $functions.moneyFormat(item.commission_value) : $functions.moneyFormat(item.commission) }}
        </span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <span>
          <v-icon
            title="Desmarcar conferência"
            v-if="item.conference == true"
            @click="handleStatus($event, null, item)"
          >
            mdi-close-circle-outline
          </v-icon>
          <v-icon
            title="Marcar como conferido"
            v-if="item.conference != true"
            color="success"
            @click="handleStatus($event, true, item)"
          >
            mdi-check
          </v-icon>
          <v-icon 
            title="Marcar como divergente"
            v-if="item.conference != false"
            color="error"
            @click="handleStatus($event, false, item)"
          >
            mdi-close
          </v-icon>
          <v-icon
            title="Desmarcar conferência"
            v-if="item.conference == false"
            @click="handleStatus($event, null, item)"
          >
            mdi-close-circle-outline
          </v-icon>
        </span>
      </template>

      <template v-slot:[`body.append`]="{}">
        <tr style="background: #fff !important;">
          <td
            v-for="l in headers.length - 7"
            :key="`table-commissions-deliveries-footer-key-${l}`"
          ></td>
          <td>
            <strong>{{ $functions.moneyFormat(deliveries.tda_total) }}</strong>
          </td>
          <td>
            <strong>{{ $functions.moneyFormat(deliveries.tde_total) }}</strong>
          </td>
          <td>
            <strong>{{
              $functions.moneyFormat(deliveries.discharge_total)
            }}</strong>
          </td>
          <td>
            <strong>{{
              $functions.moneyFormat(deliveries.palletization_total)
            }}</strong>
          </td>
          <td>
            <strong>{{
              $functions.moneyFormat(deliveries.freight_total)
            }}</strong>
          </td>
          <td>
            <strong>{{
              $functions.moneyFormat(deliveries.commission_total)
            }}</strong>
          </td>
        </tr>
      </template>
    </v-data-table>

    <br>

    <h2 v-if="collects.ctes && collects.ctes.length > 0">Coletas</h2>
    <v-data-table
      v-if="collects.ctes && collects.ctes.length > 0"
      :items="collects.ctes"
      :headers="collectHeaders"
      dense
      hide-default-footer
      disable-pagination
      :item-class="rowCollectClass"
    >
      <template v-slot:[`item.cte`]="{ item }">
        <a :href="`/#/coletas/${item.id}`" target="_blank">{{ item.cte }}</a>
      </template>
      <template v-slot:[`item.date`]="{ item }">
        <span>{{ item.date | moment('DD/MM/YYYY') }}</span>
      </template>
      <template v-slot:[`item.sender`]="{ item }">
        <span>{{ $functions.cutName(item.sender, 14) }}</span>
      </template>
      <template v-slot:[`item.recipient`]="{ item }">
        <span>{{ $functions.cutName(item.recipient, 14) }}</span>
      </template>
      <template v-slot:[`item.icms_value`]="{ item }">
        <span>{{ $functions.moneyFormat(item.icms_value) }}</span>
      </template>
      <template v-slot:[`item.freight_value`]="{ item }">
        <span>{{ $functions.moneyFormat(item.freight_value) }}</span>
      </template>
      <template v-slot:[`item.commission`]="{ item }">
        <span>{{ $functions.moneyFormat(item.commission) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <span>
          <v-icon
            title="Desmarcar conferência"
            v-if="item.conference == true"
            @click="handleCollectStatus($event, null, item)"
          >
            mdi-close-circle-outline
          </v-icon>
          <v-icon
            title="Marcar como conferido"
            v-if="item.conference != true"
            color="success"
            @click="handleCollectStatus($event, true, item)"
          >
            mdi-check
          </v-icon>
          <v-icon 
            title="Marcar como divergente"
            v-if="item.conference != false"
            color="error"
            @click="handleCollectStatus($event, false, item)"
          >
            mdi-close
          </v-icon>
          <v-icon
            title="Desmarcar conferência"
            v-if="item.conference == false"
            @click="handleCollectStatus($event, null, item)"
          >
            mdi-close-circle-outline
          </v-icon>
        </span>
      </template>
      <template v-slot:[`body.append`]="{}">
        <tr style="background: #fff !important;">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <strong>
              {{ $functions.moneyFormat(collects.freight_total) }}
            </strong>
          </td>
          <td>
            <strong>
              {{ $functions.moneyFormat(collects.commission_total) }}
            </strong>
          </td>
          <td></td>
        </tr>
      </template>
    </v-data-table>
    <br>
    <div class="totals">
      <v-btn v-if="freightValue && commissionValue" title="Fechar comissão" @click="closeCommission()" :loading="loading">
        <v-icon>mdi-check</v-icon>
        Fechar
      </v-btn>
      <span v-if="freightValue">Frete: {{ $functions.moneyFormat(freightValue) }}</span>
      <span v-if="commissionValue">Comissão: {{ $functions.moneyFormat(commissionValue) }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  watch: {
    partner() {
      this.deliveries = {
        ctes: []
      };
      this.collects = [];
    }
  },
  mounted() {
    this.loadPartners();
  },
  data() {
    return {
      loading: false,
      partners: [],
      deliveries: {
        ctes: []
      },
      collects: [],
      freightValue: null,
      commissionValue: null,
      partner: null,
      dateFrom: null,
      dateTo: null,
      headers: [
        { text: "Entrega", value: "delivered" },
        { text: "CTe", value: "number" },
        { text: "NFe", value: "nf" },
        { text: "Destinatário", value: "recipients.name" },
        { text: "Cidade", value: "recipients.cities.name" },
        { text: "TDA", value: "tda" },
        { text: "TDE", value: "tde" },
        { text: "Paletização", value: "palletization" },
        { text: "Frete", value: "freight_value" },
        { text: "Comissão", value: "commission" },
        { text: "Ações", value: "actions" },
      ],
      collectHeaders: [
        { text: "Coleta", value: "date" },
        { text: "CTe", value: "cte" },
        { text: "NFe", value: "nf" },
        { text: "Remetente", value: "sender" },
        { text: "Destino", value: "recipient" },
        { text: "ICMS", value: "icms_value" },
        { text: "Frete", value: "freight_value" },
        { text: "Comissão", value: "commission" },
        { text: "Ações", value: "actions" },
      ],
    }
  },
  methods: {
    async closeCommission() {
      if (confirm('Confirma fechamento da comissão?')) {
        this.loading = true;
        try {
          const { data } = await this.$http.post('api/commission/close', {
            partner: this.partner,
            date_from: this.dateFrom,
            date_to: this.dateTo,
          });
          this.$toast.success('Comissão fechada');
          this.$router.push(`/comissoes/${data.id}`);
        } catch (error) {
          this.$toast.error('Erro ao fechar comissão');
        }
        this.loading = false;
      }
    },
    goToRulesManagement() {
      if (this.partner) {
        this.$router.push({ name: 'partners.edit', params: { id: this.partner } });
      } else {
        this.$router.push({ name: 'partners.index' })
      }
    },
    rowClass(cte) {
      if (cte.conference === null) {
        return '';
      } else if (cte.conference == true) {
        return 'green-row';
      } else if (cte.conference == false) {
        return 'red-row';
      }
    },
    rowCollectClass(collect) {
      if (collect.conference === null) {
        return '';
      } else if (collect.conference == true) {
        return 'green-row';
      } else if (collect.conference == false) {
        return 'red-row';
      }
    },
    async handleStatus(ev, status, cte) {
      const lastValue = cte.conference;
      cte.conference = status;

      try {
        await this.$http.post(`api/commissions/${cte.id}/conference`, {status});
      } catch (error) {
        this.$toast.error('Erro ao marcar cte');
        cte.conference = lastValue;
      }
    },
    async handleCollectStatus(ev, status, collect) {
      const lastValue = collect.conference;
      collect.conference = status;

      try {
        await this.$http.post(`api/collects/${collect.id}/conference`, {status});
      } catch (error) {
        this.$toast.error('Erro ao marcar coleta');
        collect.conference = lastValue;
      }
    },
    async loadPartners() {
      this.loading = true;
      const response = await this.$http.get('api/partners');
      this.partners = response.data;
      this.loading = false;
    },
    async load() {
      if (!this.partner || !this.dateFrom || !this.dateTo) {
        return;
      }
      this.loading = true;

      try {
        const response = await this.$http.get(`api/commissions?partner=${this.partner}&date_from=${this.dateFrom}&date_to=${this.dateTo}`);
        this.deliveries = response.data.deliveries;
        this.collects = response.data.collects;
        this.freightValue = response.data.freight_total;
        this.commissionValue = response.data.commission_total;
      } catch (error) {
        if (error?.response?.data?.msg && error?.response?.status === 422) {
          this.$toast.error(error?.response?.data?.msg);
        } else {
          this.$toast.error('Erro ao carregar dados');
        }
      }

      this.loading = false;
    },
    handleShowDeliveryDate(cte) {
      if(cte.delivery_date) {
        return `
          <span 
            class="red--text" 
            title=${moment(cte.delivered).format("DD/MM/YYYY")}
          >${moment(cte.delivery_date).format("DD/MM/YYYY")}</span>`
      } else {
        return `<span>${moment(cte.delivered).format("DD/MM/YYYY")}</span>`
      }
    },
    handleTdaClass(cte) {
      const { tdClass } = this.handleTdaWarning(cte);

      return tdClass;
    },
    handleTdaTitle(cte) {
      const { tdaValue } = this.handleTdaWarning(cte);

      return tdaValue > 0 ? `Esperado ${ this.$functions.moneyFormat(tdaValue) }` : ''; 
    },
    handleTdaWarning(cte) {
      let tdClass = "";
      let tdaValue = null;
      
      if (
        parseFloat(cte.recipients.tda) > 0 && 
        parseFloat(cte.tda) !== parseFloat(cte.recipients.tda)
      ) {
        tdClass = "red-decoration";
        tdaValue = cte.recipients.tda;
      } else {
        cte.recipients.cities?.city_fees?.forEach(fee => {
          if(
            fee.partner_id === cte.partner_id &&
            fee.city_id === cte.recipients.city_id && 
            fee.value !== cte.tda
          ) {
            tdClass = "red-decoration";
            tdaValue = fee.value;
          }
        });
      }

      return { tdClass, tdaValue };
    },
    handleTdeClass(cte) {
      const { tdClass } = this.handleTdeWarning(cte);

      return tdClass;
    },
    handleTdeTitle(cte) {
      const { tdeValue } = this.handleTdeWarning(cte);

      return parseFloat(tdeValue) > 0 ? `Esperado ${ this.$functions.moneyFormat(tdeValue) }` : ''; 
    },
    handleTdeWarning(cte) {
      let tdClass = "";
      let tdeValue = null;
      
      if(
        parseFloat(cte.recipients.tde) > 0 && 
        parseFloat(cte.tde) !== parseFloat(cte.recipients.tde)
      ) {
        tdClass = "red-decoration";
        tdeValue = cte.recipients.tde;
      }

      return { tdClass, tdeValue };
    },
  }
}
</script>

<style lang="scss" scoped>
.totals {
  display: flex;
  justify-content: right;
  gap: 1em;
  margin: 1em;

  span {
    border: solid 2px #777;
    border-radius: 5px;
    padding: 2px 5px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 2px 2px 5px #777;
  }
}

.red-decoration {
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: warning 1s;
    animation-iteration-count: infinite;
  }
}

@keyframes warning {
  0% {
    transform: scale(1);
    background-color: red;
    opacity: 0.7;
  }
  100% {
    transform: scale(2);
    background-color: red;
    opacity: 0;
    border-radius: 20px;
  }
}
</style>
