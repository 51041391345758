var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Regras de clientes para coletas")]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"success","fab":"","small":""},on:{"click":function($event){return _vm.$router.push('/regras-clientes/adicionar')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"secondary","fab":"","small":""},on:{"click":_vm.getCustomerRules}},[_c('v-icon',[_vm._v("mdi-refresh")])],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"disable-pagination":"","hide-default-footer":"","dense":"","items":_vm.items,"headers":[
			{text: 'Nome', value: 'rule_name'},
			{text: 'Código', value: 'rule_code'},
			{text: 'Parceira', value: 'partners.name'},
			{text: 'Porcentagem', value: 'percentage'},
			{text: 'Frete Mínimo', value: 'min_freight'},
			{text: 'Desconto ICMS', value: 'discount_icms'},
			{text: 'Ações', value: 'actions'} ]},scopedSlots:_vm._u([{key:"item.min_freight",fn:function(ref){
		var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$functions.moneyFormat(item.min_freight)))])]}},{key:"item.discount_icms",fn:function(ref){
		var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.discount_icms ? 'Sim' : 'Não'))])]}},{key:"item.actions",fn:function(ref){
		var item = ref.item;
return [_c('Delete',{attrs:{"rule":item},on:{"deleted":_vm.getCustomerRules}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }