<template>
  <div>
    <h3>Configurar envio de e-mail</h3>

    <v-progress-circular v-if="!partner" indeterminate></v-progress-circular>

    <v-form ref="formUpdateMailConfig" :loading="loading" @submit.prevent="handleUpdateMailConfig" v-if="partner">
      <v-text-field
        label="Destinatários"
        v-model="partner.mail_recipients"
        :rules="validateEmail"
      ></v-text-field>
      <small>Separe com ;</small>

      <br>

      <v-btn
        color="success"
        type="submit"
        :loading="loading"
      >Salvar</v-btn>
    </v-form>
  </div>
</template>

<script>
  export default {
    props: {
      partner: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        loading: false,
        validateEmail: [
          v => {
            if(v) {

              if(v.includes(',')) {
                return 'Não use vírgula para separar';
              }

              let emails = v.split(';');
              let isOk = true;
              let emailPosition = null;
              
              emails.map((email, index) => {
                let e = email.trim()
                
                if(!e.includes('@') || !e.includes('.')) {
                  isOk = false;
                  emailPosition = index + 1;
                }
              });
              
              return isOk ? isOk : `O ${emailPosition}º email não é válido`
            }

            return true;
          }
        ]
      }
    },
    methods: {
      handleUpdateMailConfig() {        
        if(this.$refs.formUpdateMailConfig) {
          this.loading = true;
          this.$http.put(`api/partners/${this.partner.id}`, this.partner)
            .then(() => {
              this.loading = false;
              this.$toast.success('Salvo com sucesso');
              this.$refs.formUpdateMailConfig.resetValidation();
            })
            .catch(err => {
              this.loading = false;
              console.log(err);
              this.$toast.error('Erro ao salvar');
            });
        }
      }
    },
  }
</script>
