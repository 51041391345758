<template>
	<div>
		<v-form ref="formSaveCustomerRule" @submit.prevent="handleSubmit">
			<v-row>
				<v-col cols="12" md=4>
					<v-text-field
						label="Nome da regra*"
						:rules="rules"
						v-model="rule.rule_name"
					/>
				</v-col>
				<v-col cols="12" md=4>
					<v-text-field
						label="Código da regra*"
						:rules="rules"
						v-model="rule.rule_code"
					/>
				</v-col>
				<v-col cols="12" md=4>
					<v-select 
						:items="partners"
						item-text="name"
						item-value="id"
						label="Parceira*"
						:rules="rules"
						v-model="rule.partner_id"
						:loading="partners.length < 1"
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="4">
					<v-text-field
						label="Porcentagem"
						v-model="rule.percentage"
					></v-text-field>
				</v-col>
				<v-col cols="12" md="4">
					<v-text-field
						label="Frete Mínimo"
						v-model="rule.min_freight"
					></v-text-field>
				</v-col>
				<v-col cols="12" md="4">
					<v-switch
						label="Desconto ICMS"
						v-model="rule.discount_icms"
					></v-switch>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-btn 
						color="success"
						type="submit"
					>Salvar</v-btn>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>

<script>
	export default {
		mounted() {
			this.getPartners();
		},
		data() {
			return {
				rules: [
					v => !! v || 'Este campo é obrigatório'
				],
				partners: [],
				rule: {
					rule_name: null,
					rule_code: null,
					partner_id: null,
					percentage: null,
					min_freight: null,
					discount_icms: null
				}
			}
		},
		methods: {
			cleanForm() {
				this.$refs.formSaveCustomerRule.reset()
			},
			handleSubmit() {
				if(this.$refs.formSaveCustomerRule.validate()) {
					this.$emit('submited', this.rule);
					this.cleanForm();
				}
			},
			getPartners() {
				this.$http.get('api/partners')
					.then(({ data }) => this.partners = data)
					.catch(err => {
						console.log(err);
						this.$side({
							type: 'error',
							msg: 'Erro ao carregar parcerias'
						});
					});
			}
		}
	}
</script>