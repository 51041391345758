<template>
	<div>
		<h1>Adicionar Regra</h1>

		<v-btn 
			color="error" 
			fab 
			small 
			class="ma-2"
			@click="$router.push('/regras-clientes')"
		>
			<v-icon>mdi-keyboard-return</v-icon>
		</v-btn>

		<Form @submited="saveCustomerRule" />
	</div>
</template>

<script>
import Form from '../components/Form';

	export default {
		components: {
			Form,
		},
		methods: {
			saveCustomerRule(rule) {
				this.$http.post(`api/customer-rules`, rule)
					.then(() => {
						this.$side({
							type    : 'success',
							msg     : 'Salvo com sucesso',
							duration: 4000
						});
					}).catch(err => {
						console.log(err);
						this.$side({
							type    : 'error',
							msg     : 'Erro ao salvar regra',
							duration: 4000
						});
					});
			}
		},
	}
</script>