import axios from 'axios';
import store from '../store';
import auth from '../modules/auth';

let url = '';

if (process.env.VUE_APP_PRODUCTION_TIP == 'true') {
	url = 'http://api.transporteslatina.com.br/';
} else {
	url = 'http://localhost:8000/';
}

var http = axios.create({
	baseURL: url,
	headers: {
		Accept: 'application/json'
	}
});

http.interceptors.request.use(
	(config) => {
		let token = store.state.token;

		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

http.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		if (error.response.status === 500) {
			console.log('Erro no servidor');
		}
		if (error.response.status === 401 || error.response.status === 403) {
			auth.logout();
		}

		return Promise.reject(error);
	}
);

export { url };

export default http;
