<template>
  <div>
    <div class="flex mb-2">
      <h3>Regras (Coletas)</h3>

      <v-btn color="success" fab small @click="show = !show">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      disable-pagination
      hide-default-footer
      disable-sort
      disable-filtering
      :loading="loading"
      @click:row="openEdit($event)"
      :item-class="() => 'cursor-pointer'"
    >
      <template v-slot:[`item.city`]="{ item }">
        <span>{{ item.all_cities ? 'Todas' : item.cities.name }}</span>
      </template>
      <template v-slot:[`item.icms`]="{ item }">
        <span>{{ item.icms ? 'Sim' : 'Não' }}</span>
      </template>
      <template v-slot:[`item.toll_discount`]="{ item }">
        <span>{{ item.toll_discount ? 'Sim' : 'Não' }}</span>
      </template>
      <template v-slot:[`item.percentage`]="{ item }">
        <span>{{ item.percentage }}%</span>
      </template>
      <template v-slot:[`item.min_freight`]="{ item }">
        <span>{{ $functions.moneyFormat(item.min_freight) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <span>
          <v-icon color="error" title="Excluir rule" @click="($event) => deleteRule($event, item.id)">mdi-delete</v-icon>
        </span>
      </template>
    </v-data-table>

    <v-dialog v-if="show" v-model="show" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ rule && rule.id ? `Editar regra ${rule.name ? rule.name : ''}` : 'Nova regra' }}
        </v-card-title>

        <v-card-text>
          <v-form @submit.prevent="save" ref="formManageRule" :disabled="loading || saving">
            <v-row>
              <v-col>
                <v-switch
                  label="Todas as cidades"
                  v-model="rule.all_cities"
                ></v-switch>
              </v-col>
              <v-col>
                <v-select
                  label="Cidade"
                  :items="cities"
                  v-model="rule.city_id"
                  item-text="name"
                  item-value="id"
                  :rules="[
                    v => !!v || 'Obrigatório'
                  ]"
                  v-if="!rule.all_cities"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch
                  label="Desconto ICMS"
                  v-model="rule.icms"
                ></v-switch>
              </v-col>
              <v-col>
                <v-switch
                  label="Desconto Pedágio"
                  v-model="rule.toll_discount"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  label="Porcentagem comissão"
                  v-model="rule.percentage"
                  :rules="[
                    v => !!v || 'Obrigatório'
                  ]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Frete mínimo"
                  v-model="rule.min_freight"
                  :rules="[
                    v => !!v || 'Obrigatório'
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-progress-circular indeterminate v-if="loading || saving"></v-progress-circular>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="show = false"
            :disabled="loading || saving"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            text
            @click="save"
            :disabled="loading || saving"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [
    'partner'
  ],
  watch: {
    show() {
      if (!this.show) {
        this.rule = {
          id: null,
          partner_id: this.partner,
          percentage: null,
          city_id: null,
          all_cities: null,
          icms: null,
          toll_discount: null,
          min_freigh: null,
        }
      }

      if (this.rule?.id) {
        this.loading = true;
        this.$http.get(`api/collect-rules/${this.rule.id}`)
          .then(response => {
            this.rule = response.data;
            this.loading = false;
          })
          .catch(error => {
            this.$toast.error('Erro ao carregar regra');
            this.loading = false;
          });
      }
    }
  },
  mounted() {
    this.load();
  },
  data() {
    return {
      headers: [
        { text: "Cidade", value: "city" },
        { text: "Desc. ICMS", value: "icms" },
        { text: "Desc. Pedágio", value: "toll_discount" },
        { text: "Porcentagem", value: "percentage" },
        { text: "Frete Mínimo", value: "min_freight" },
        { text: "Ações", value: "actions" },
      ],
      items: [],
      cities: [],
      loading: false,
      saving: false,
      show: false,
      rule: {
        id: null,
        partner_id: this.partner,
        percentage: null,
        city_id: null,
        all_cities: null,
        icms: null,
        toll_discount: null,
        min_freigh: null,
      }
    }
  },
  methods: {
    async deleteRule(evt, id) {
      evt.stopPropagation();
      if (confirm('Deseja realmente excluir esta regra?')) {
        this.loading = true;
        try {
          await this.$http.delete(`api/collect-rules/${id}`);
          this.load();
        } catch (error) {
          this.$toast.error('Erro ao excluir regra');
          this.loading = false;
        }
      }
    },
    async save() {
      if (this.$refs.formManageRule.validate()) {
        this.saving = true;

        try {
          if (this.rule.id) {
            await this.$http.put(`api/collect-rules/${this.rule.id}`, this.rule);
          } else {
            await this.$http.post('api/collect-rules', this.rule);
          }
          this.$toast.success('Salvo com sucesso');
          this.show = false;
          this.load(false);
        } catch (error) {
          this.$toast.error('Erro ao salvar regra');
        }

        this.saving = false;
      }
      
    },
    openEdit(evt) {
      this.rule.id = evt.id;
      this.show = true;
    },
    async loadCities() {
      const { data } = await this.$http.get('api/cities')
      this.cities = data;
    },
    async load(cities = true) {
      this.loading = true;

      try {
        const { data } = await this.$http.get(`api/collect-rules?partner=${this.partner}`)
        if (cities) {
          await this.loadCities();
        }

        this.items = data;
      } catch (error) {
        this.$toast.error('Erro ao carregar regras de coleta');
      }

      this.loading = false;
    }
  }
};
</script>

<style>
</style>