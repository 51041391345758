<template>
  <div>
    <h1>Editar Parceira</h1>

    <v-btn
      fab
      small
      color="error"
      class="ma-2"
      @click="$router.push({ name: 'partners.index' })"
    >
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <v-row>
      <v-col cols="12" md="6">
        <Form :saving="saving" @submited="handleUpdate" :partner="partner" />
        <br><hr><br>
        <MailConfig :partner="partner" />
      </v-col>
      <v-col cols="12" md="6">
        <h3>Agrupamentos para comissão</h3>

        <CommissionGroups :partner_id="$route.params.id" />
      </v-col>
    </v-row>

    <br><hr><br>

    <h2>Regras</h2>

    <Variations :partner="$route.params.id" />

    <br>

    <Rules :partner="$route.params.id" />
  </div>
</template>

<script>
import Form from '../components/Form.vue';
import CommissionGroups from '../components/CommissionGroups.vue';
import MailConfig from '../components/MailConfig.vue';
import Variations from '../components/Variations.vue';
import Rules from '../components/Rules.vue';

  export default {
    components: {
      Form,
      CommissionGroups,
      MailConfig,
      Variations,
      Rules,
    },
    mounted () {
      this.getPartner();
    },
    data() {
      return {
        partner: null,
        saving: false,
      }
    },
    methods: {
      handleUpdate(partner) {
        this.saving = true;
        this.$http.put(`api/partners/${partner.id}`, partner)
          .then(() => {
            this.saving = false;
            this.$toast.success('Salvo com sucesso');
            this.$router.push({ name: 'partners.index'});
          }).catch(err => {
            this.saving = false;
            console.log(err);
            this.$toast.error('Erro ao salvar');
          });
      },
      getPartner() {
        this.$http.get(`api/partners/${this.$route.params.id}/edit`)
          .then(({data}) => this.partner = data)
          .catch(() => this.$toast.error('Erro ao carregar parceira'));
      }
    },
  }
</script>