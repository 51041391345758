<template>
  <div>
    <h1>Comissões fechadas</h1>

    <v-btn
      color="error"
      fab
      small
      @click="$router.back()"
      class="ma-2"
    >
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <v-btn
      color="success"
      fab
      small
      @click="$router.push({ name: 'commissions.index' })"
      class="ma-2"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-btn
      color="secondary"
      fab
      small
      @click="getCommissions"
      class="ma-2"
    >
      <v-icon>mdi-refresh</v-icon>
    </v-btn>

    <v-btn
      color="primary"
      class="ma-2"
      @click="handleShowFilters"
    >Filtrar</v-btn>

    <div class="filter">
      <button @click="handleShowFilters" class="close-button">x</button>

      <v-form>
        <v-select 
          label="Parceira"
          :items="partners"
          :loading="partners.length < 1"
          item-text="name"
          item-value="id"
          v-model="params.partner"
        />
        <v-text-field 
          label="Data inicial"
          type="date"
          v-model="params.initial"
        />
        <v-text-field 
          label="Data final"
          type="date"
          v-model="params.final"
        />
        <v-btn
          color="success"
          @click="filterCommissions"
          :loading="loading"
        >Filtrar</v-btn>
        
        <v-btn
          color="error"
          @click="removeFilters"
          :loading="loading"
        >Remover Filtro</v-btn>
      </v-form>
    </div>

    <v-data-table
      :items="commissions"
      :headers="[
        {text: 'ID', value: 'id'},
        {text: 'Parceira', value: 'partner.name'},
        {text: 'Período', value: 'date'},
        {text: 'Comissões', value: 'total_commission'},
      ]"
      dense
      disable-pagination
      hide-default-footer
      :loading="loading"
      :item-class="() => `cursor-pointer`"
      @click:row="handleClick"
    >
      <template v-slot:[`item.date`]="{ item }">
        <span>
          {{ item.initial_date | moment("DD/MM/YYYY") }} à 
          {{ item.final_date | moment("DD/MM/YYYY") }}
        </span>
      </template>

      <template v-slot:[`item.total_commission`]="{ item }">
        <span>
          {{ $functions.moneyFormat(item.total_commission) }}
        </span>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.current"
      :length="pagination.total"
      @input="onPageChange"
    ></v-pagination>
  </div>
</template>

<script>
import moment from 'moment';

  export default {
    mounted () {
      this.getCommissions();
      this.getPartners();
    },
    data() {
      return {
        commissions: [],
        loading: false,
        partners: [],
        params: {
          partner: null,
          initial: null,
          final: null,
        },
        pagination: {
          current: 1,
          total: 0
        },
      }
    },
    methods: {
      removeFilters() {
        this.handleShowFilters();
        this.getCommissions();
      },
      onPageChange() {
        this.getCommissions();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      async filterCommissions() {
        this.loading = true;

        try {
          let { data } = await this.$http.get(`api/commission/search?partner_id=${this.params.partner}&initial_date=${this.params.initial}&final_date=${this.params.final}`);
          this.commissions = data;

          this.pagination.current = 1;
          this.pagination.total = 0;

          this.handleShowFilters();
        } catch(error) {
          console.log(error);
          this.$toast.error('Erro ao carregar comissões');
        }

        this.loading = false;
      },
      async getPartners() {
        try {
          let { data } = await this.$http.get(`api/partners`);
          this.partners = data;
        } catch(error) {
          console.log(error);
          this.$toast.error('Erro ao carregar parceiras');
        }
      },
      handleShowFilters() {
        let filtersDiv = document.querySelector('.filter');

        if(filtersDiv.classList.contains('showing')) {
          filtersDiv.classList.remove('showing');
          filtersDiv.classList.add('not-showing');
        } else {
          filtersDiv.classList.remove('not-showing');
          filtersDiv.classList.add('showing');
        }
      },
      handleClick(event) {
        this.$router.push({ name: 'commissions.show', params: { id: event.id} });
      },

      async getCommissions() {
        this.loading = true;
        
        const { data } = await this.$http.get(`api/commission/get-commissions?page=${this.pagination.current}`)
          .catch(err => {
            console.log(err);
            this.$toast.error('Erro ao carregar comissões');
          });

        this.commissions = data.data;
        this.pagination.current = data.current_page;
        this.pagination.total = data.last_page;
        this.loading = false;
      }
    },
  }
</script>

<style lang="scss" scoped>
  .filter {
    position: absolute;
    width: 500px;
    height: 400px;
    background: #fff;
    top: 50px;
    left: -600px;
    box-shadow: 2px 2px 5px #444;
    border-radius: 10px;

    z-index: 99999999px;

    form {
      margin-top: 50px;
      padding: 20px;
    }
  }

  .showing {
    animation: show-on 0.5s;
    animation-fill-mode: forwards;
  }
  
  .not-showing {
    animation: show-off 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes show-on {
    from {
      left: -100%;
    }
    to {
      left: 50px;
    }
  }
  @keyframes show-off {
    from {
      left: 50px;
    }
    to {
      left: -100%;
    }
  }
</style>