import router from '../router';

export default {
	set_token({ commit }, token) {
		localStorage.setItem('access_token', token);
		commit('set_token', token);
		router.push('/');
	},
	set_user({ commit }, user) {
		localStorage.setItem('user', JSON.stringify(user));
		commit('set_user', user);
	},
	logout({ commit }) {
		commit('set_token', null);
		commit('set_user', null);
		localStorage.removeItem('access_token');
		localStorage.removeItem('user');
	},
	set_current_cnpj({ commit }, current_cnpj) {
		commit('set_current_cnpj', current_cnpj);
	},
	set_date_deliveries({ commit }, dates) {
		commit('set_date_deliveries', dates);
	},
	set_items_per_page({ commit }, items) {
		commit('set_items_per_page', items);
	},
	set_ctes_search({ commit }, search) {
		commit('set_ctes_search', search);
	},
	set_colors({ commit }, colors) {
		commit('set_colors', colors);
		localStorage.setItem('colors', JSON.stringify(colors));
	},
	set_current_month({ commit }, month) {
		commit('set_current_month', month);
	},
	set_current_year({ commit }, year) {
		commit('set_current_year', year);
	},
	set_account({ commit }, data) {
		commit('set_account', data);
	},
	set_partners({ commit }, partners) {
		commit('set_partners', partners);
	},
	set_table_size({ commit }, tableSize) {
		localStorage.setItem('table_size', tableSize);
		commit('set_table_size', tableSize);
	}
};
