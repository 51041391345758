import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import VueToastConfirm from 'vue-toast-confirm';
import auth from './modules/auth';
import functions from './modules/functions';
import http from './modules/http';

import VueToast from 'vue-toast-notification';

import 'vue-toast-notification/dist/theme-sugar.css';

Vue.prototype.$auth = auth;
Vue.prototype.$functions = functions;
Vue.prototype.$http = http;

Vue.config.productionTip = process.env.VUE_APP_PRODUCTION_TIP == 'true' ? true : false;

Vue.use(VueToastConfirm);
Vue.use(require('vue-moment'));

Vue.use(VueToast, {
	position: 'top'
});

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App)
}).$mount('#app');
