export default [
  {
    path: '/romaneios-completo',
    name: 'deliveries.Index',
    component: () => import(/* webpackChunkName: "deliveries.index" */ './pages/Index.vue'),
  },
  {
    path: '/criar-romaneio',
    name: 'deliveries.Create',
    component: () => import(/* webpackChunkName: "deliveries.create" */ './pages/Create.vue'),
  },
  {
    path: '/romaneios',
    name: 'deliveries.FastCreate',
    component: () => import(/* webpackChunkName: "deliveries.fastCreate" */ './pages/FastCreate.vue')
  },
  {
    path: '/editar-romaneio/:id',
    name: 'deliveries.Edit',
    component: () => import(/* webpackChunkName: "deliveries.edit" */ './pages/Edit.vue')
  }
]