const func = {
  removeNull(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != '')
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  },
  removeEmpty(obj) {
    return Object.entries(obj)
      .filter(([_, v]) => v != null)
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
  },
  moneyFormat(value) {
    if(!value) {
      value = 0;
    }

    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    value = value.toFixed(2);

    return 'R$ ' + value.toString().replace('.', ',').replace(/(\d)(?=(\d{3})+,)/g, '$1.');
  },
  money(value) {
    if(!value) {
      return 0;
    }

    if(typeof(value) === 'string') {
      value = parseFloat(value);
    }

    value = value.toFixed(2).toString();
    value = value.replace('.', ',');
    return `R$ ${value}`;
  },
  validationToArray(obj) {
    return Object.entries(obj.response.data.errors);
  },
  cutName(name, ref_num = 15) {
    if (name.length > ref_num) {
      return name.substring(0, ref_num - 3) + "...";
    } else {
      return name;
    }
  },
}

export default func