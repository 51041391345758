import authorization from '@/modules/auth'
import store from '@/store'

export default [
	{
		path: '/regras-comissao',
		name: 'commissionRules.index',
		component: () => import(/* webpackChunkName: "commissionRules.index" */ './pages/Index.vue'),
		beforeEnter: (to, from, next) => {
      if(authorization.isAuthorized('manager')) {
        return next()
      } else {
        store.dispatch('logout');
      }
    }
	}
]