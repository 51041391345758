export default [
	{
		path: '/ocorrencias',
		name: 'occurrences.index',
		component: () => import(/* webpackChunkName: "occurrences.index" */ './pages/Index.vue')
	},
	{
		path: '/tipos-ocorrencias',
		name: 'occurrences.types',
		component: () => import(/* webpackChunkName: "occurrences.types" */ './pages/Types.vue')
	},
]