<template>
  <div>
    <v-progress-circular
      indeterminate
      size="20"
      color="red"
      v-if="deleting"
    ></v-progress-circular>
    <v-icon
      color="error"
      @click="deleteRule"
      v-if="!deleting"
    >mdi-delete</v-icon>
  </div>
</template>

<script>
  export default {
    props: {
      rule: {
        type: Object,
        default: null
      },
    },
    data() {
      return {
        deleting: false
      }
    },
    methods: {
      deleteRule() {
        if(confirm('Tem certeza que deseja excluir esta regra?')) {
          this.deleting = true;
          this.$http.delete(`api/customer-rules/${this.rule.id}`)
          .then(() => {
            this.$emit('deleted');
          }).catch(err => {
            this.deleting = false;
            this.$side({
              type    : 'error',
              msg     : 'Erro ao excluir regra',
              duration: 4000
            });
          });
        }
      }
    },
  }
</script>