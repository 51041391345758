<template>
  <div>
    <div class="flex mb-2">
      <h3>Variações (Entregas)</h3>

      <v-btn color="success" fab small @click="show = !show">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      disable-pagination
      hide-default-footer
      disable-sort
      disable-filtering
      :loading="loading"
      @click:row="openEdit($event)"
      :item-class="() => 'cursor-pointer'"
    >
      <template v-slot:[`item.name`]="{ item }">
        <v-tooltip right color="#000">
          <template v-slot:activator="{ on, attrs }">
            <span 
              v-bind="attrs"
              v-on="on"
            >{{
              item.name
            }}</span>
          </template>
          <div>
            <p>Outras informações:</p>
            <ul>
              <li>Nome: {{ item.name }}</li>
              <li>Cidade: {{ item.default_variation ? 'Padrão' : item.cities.name }}</li>
              <li>Desconto TDA: {{ item.tda_discount ? 'Sim' : 'Não' }}</li>
              <li>Desconto TDE: {{ item.tde_discount ? 'Sim' : 'Não' }}</li>
              <li>Desconto ICMS: {{ item.icms_discount ? 'Sim' : 'Não' }}</li>
              <li>Desconto Pedágio: {{ item.toll_discount ? 'Sim' : 'Não' }}</li>
              <li>Porcentagem TDA: {{ $functions.moneyFormat(item.commission_tda) }}</li>
              <li>Porcentagem TDE: {{ $functions.moneyFormat(item.commission_tde) }}</li>
              <li>Porcentagem Comissão: {{ $functions.moneyFormat(item.commission_percentage) }}</li>
              <li>Frete mínimo: {{ $functions.moneyFormat(item.min_freight) }}</li>
            </ul>
          </div>
        </v-tooltip>
      </template>
      <template v-slot:[`item.default_variation`]="{ item }">
        <span>{{ item.default_variation ? 'Sim' : 'Não' }}</span>
      </template>
      <template v-slot:[`item.city`]="{ item }">
        <span>{{ item.default_variation ? 'Padrão' : item.cities.name }}</span>
      </template>
      <template v-slot:[`item.commission_percentage`]="{ item }">
        <span>{{ item.commission_percentage }}%</span>
      </template>
      <template v-slot:[`item.min_freight`]="{ item }">
        <span>{{ $functions.moneyFormat(item.min_freight) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <span>
          <v-icon v-if="!item.default_variation" color="error" title="Excluir variação" @click="($event) => deleteVariation($event, item.id)">mdi-delete</v-icon>
        </span>
      </template>
    </v-data-table>

    <v-dialog v-if="show" v-model="show" persistent>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ variation.id ? `Editar variação ${variation.name ? variation.name : ''}` : 'Nova variação' }}
        </v-card-title>

        <v-card-text>
          <v-form @submit.prevent="save" ref="formManageVariation" :disabled="loading || saving">
            <div v-if="items && items.length > 0 && !variation.default_variation">
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nome"
                    v-model="variation.name"
                    :rules="[
                      v => !!v || 'Obrigatório'
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    label="Cidade"
                    :items="cities"
                    item-text="name"
                    item-value="id"
                    :loading="cities.length < 1"
                    v-model="variation.city_id"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>
            <div>
              <v-row>
                <v-col>
                  <v-switch
                    label="Desconto TDA"
                    v-model="variation.tda_discount"
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-switch
                    label="Desconto TDE"
                    v-model="variation.tde_discount"
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-switch
                    label="Desconto ICMS"
                    v-model="variation.icms_discount"
                  ></v-switch>
                </v-col>
                <v-col>
                  <v-switch
                    label="Desconto Pedágio"
                    v-model="variation.toll_discount"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="TDA %"
                    v-model="variation.commission_tda"
                    :rules="[
                      v => !!v || 'Obrigatório'
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="TDE %"
                    v-model="variation.commission_tde"
                    :rules="[
                      v => !!v || 'Obrigatório'
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Porcentagem"
                    v-model="variation.commission_percentage"
                    :rules="[
                      v => !!v || 'Obrigatório'
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="Frete mínimo"
                    v-model="variation.min_freight"
                    :rules="[
                      v => !!v || v === 0 || 'Obrigatório'
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-progress-circular indeterminate v-if="loading || saving"></v-progress-circular>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="show = false"
            :disabled="loading || saving"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            text
            @click="save"
            :disabled="loading || saving"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [
    'partner',  
  ],
  watch: {
    show() {
      if (!this.show) {
        this.variation = {
          id: null,
          partner_id: this.partner,
          city_id: null,
          default_variation: this.items?.length < 1 ? true : false,
          name: null,
          tda_discount: false,
          tde_discount: false,
          icms_discount: false,
          commission_tda: 100,
          commission_tde: 100,
          commission_percentage: null,
          min_freight: 0,
          toll_discount: false,
        }
      }

      if (this.variation.id) {
        this.loading = true;
        this.$http.get(`api/commission/variations/${this.variation.id}`)
          .then(response => {
            this.variation = response.data;
            this.loading = false;
          })
          .catch(error => {
            this.$toast.error('Erro ao carregar variação');
            this.loading = false;
          });
      }
    }
  },
  mounted() {
    this.load();
    this.loadCities();
  },
  data() {
    return {
      loading: false,
      saving: false,
      show: false,
      cities: [],
      headers: [
        { text: "Nome", value: "name" },
        { text: "Cidade", value: "city" },
        { text: "Porcentagem", value: "commission_percentage" },
        { text: "Mínimo", value: "min_freight" },
        { text: "Ações", value: "actions" },
      ],
      items: [],
      variation: {
        id: null,
        partner_id: this.partner,
        city_id: null,
        default_variation: this.items?.length > 0 ? true : false,
        name: null,
        tda_discount: false,
        tde_discount: false,
        icms_discount: false,
        commission_tda: 100,
        commission_tde: 100,
        commission_percentage: null,
        min_freight: 0,
        toll_discount: false,
      }
    };
  },
  methods: {
    async deleteVariation(evt, id) {
      evt.stopPropagation();
      if (confirm('Deseja realmente excluir esta variação?')) {
        this.loading = true;
        try {
          await this.$http.delete(`api/commission/variations/${id}`);
          this.load();
        } catch (error) {
          this.$toast.error('Erro ao excluir variação');
          this.loading = false;
        }
      }
    },
    openEdit(evt) {
      this.variation.id = evt.id;
      this.show = true;
    },
    async loadCities() {
      this.loading = true;
      try {
        const { data } = await this.$http.get('api/cities');
        this.cities = data;
      } catch (error) {
        this.$toast.error('Erro ao carregar cidades');
      }
      this.loading = false;
    },
    async load() {
      this.loading = true;
      try {
        const response = await this.$http.get(`api/commission/variations?partner_id=${this.partner}`);
        this.items = response.data;
      } catch (error) {
        this.$toast.error('Erro ao carregar variações');
      }
      this.loading = false;
    },
    async save() {
      if (this.$refs.formManageVariation.validate()) {
        this.saving = true;
        
        try {
          if (this.variation.id) {
            await this.$http.put(`api/commission/variations/${this.variation.id}`, this.variation);
          } else {
            await this.$http.post('api/commission/variations', this.variation);
          }
          
          this.$toast.success('Salvo com sucesso');
          this.show = false;
          this.load();
        } catch (error) {
          this.$toast.error('Erro ao salvar');
        }

        this.saving = false;
      }
    },
  }
};
</script>

<style>
</style>