export default [
  {
    path: "/cidades",
    name: "Cities.index",
    component: () =>
      import(/* webpackChunkName: "Cities.index" */ "./pages/Index.vue"),
  },
  {
    path: "/adicionar-cidade",
    name: "Cities.create",
    component: () =>
      import(/* webpackChunkName: "Cities.create" */ "./pages/Create.vue"),
  },
  {
    path: "/editar-cidade/:id",
    name: "Cities.edit",
    component: () =>
      import(/* webpackChunkName: "Cities.edit" */ "./pages/Edit.vue"),
  },
  {
    path: "/cidades-com-taxa",
    name: "Cities.withTda",
    component: () =>
      import(/* webpackChunkName: "Cities.withTda" */ "./pages/withTda.vue"),
  },
  {
    path: "/relacao-de-pracas",
    name: "Cities.details",
    component: () =>
      import(/* webpackChunkName: "Cities.details" */ "./pages/Details.vue"),
  },
  {
    path: "/editar-taxas",
    name: "Cities.editTda",
    component: () =>
      import(/* webpackChunkName: "Cities.editTda" */ "./pages/EditTda.vue"),
  },
];
