import authorization from '@/modules/auth'
import store from '@/store'
import Index from './pages/Index.vue'
import Show from './pages/Show.vue'
import List from './pages/List.vue'

export default [
	{
		path: '/comissoes',
		name: 'commissions.index',
		component: Index,
		beforeEnter: (to, from, next) => {
      if(authorization.isAuthorized('manager')) {
        return next()
      } else {
				store.dispatch('logout');
      }
    }
	},
	{
		path: '/comissoes-fechadas',
		name: 'commissions.list',
		component: List,
		beforeEnter: (to, from, next) => {
      if(authorization.isAuthorized('manager')) {
        return next()
      } else {
				store.dispatch('logout');
      }
    }
	},
	{
		path: '/comissoes/:id',
		name: 'commissions.show',
		component: Show,
		beforeEnter: (to, from, next) => {
      if(authorization.isAuthorized('manager')) {
        return next()
      } else {
				store.dispatch('logout');
      }
    }
	},
]