<template>
  <div ref="qMenu" class="quick-menu">
    <span v-for="route in routes" :key="route.path">
      <v-btn
        v-if="verifyCurrentRoute(route.path) && $auth.isAuthorized(route.role)"
        class="mx-2"
        @click="go(route.path)"
        >{{ route.label }}</v-btn
      >
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      routes: [
        { label: "CTEs", path: "/ctes", role: "user" },
        { label: "+ CTE", path: "/adicionar-cte", role: "user" },
        { label: "Busca", path: "/consulta-cte", role: "user" },
        { label: "Romaneios", path: "/romaneios-completo", role: "user" },
        { label: "+ Romaneios", path: "/criar-romaneio", role: "user" },
        { label: "Comissões", path: "/comissoes", role: "manager" },
      ],
    };
  },
  methods: {
    verifyCurrentRoute(route) {
      if (this.$route.path === "/" && route === "/ctes") {
        return false;
      }
      return this.$route.path !== route ? true : false;
    },
    go(route) {
      if (this.$route.path !== route) {
        this.$router.push(route);
      }
    },
    activeMenu() {
      this.$refs.qMenu.style.top = "0px";
    },
    desactiveMenu() {
      this.$refs.qMenu.style.top = "-80px";
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-menu {
  top: 0;
  width: 100%;
  height: 80px;
  background-image: linear-gradient(#344, #222);
  z-index: 1;
  transition: 0.5s;
  display: flex;
  align-items: center;
  box-shadow: 0 5px 10px black;
}

@media (max-width: 768px) {
  .quick-menu {
    display: none;
  }
}
</style>
