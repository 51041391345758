var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex mb-2"},[_c('h3',[_vm._v("Variações (Entregas)")]),_c('v-btn',{attrs:{"color":"success","fab":"","small":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"disable-pagination":"","hide-default-footer":"","disable-sort":"","disable-filtering":"","loading":_vm.loading,"item-class":function () { return 'cursor-pointer'; }},on:{"click:row":function($event){return _vm.openEdit($event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":"","color":"#000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.name))])]}}],null,true)},[_c('div',[_c('p',[_vm._v("Outras informações:")]),_c('ul',[_c('li',[_vm._v("Nome: "+_vm._s(item.name))]),_c('li',[_vm._v("Cidade: "+_vm._s(item.default_variation ? 'Padrão' : item.cities.name))]),_c('li',[_vm._v("Desconto TDA: "+_vm._s(item.tda_discount ? 'Sim' : 'Não'))]),_c('li',[_vm._v("Desconto TDE: "+_vm._s(item.tde_discount ? 'Sim' : 'Não'))]),_c('li',[_vm._v("Desconto ICMS: "+_vm._s(item.icms_discount ? 'Sim' : 'Não'))]),_c('li',[_vm._v("Desconto Pedágio: "+_vm._s(item.toll_discount ? 'Sim' : 'Não'))]),_c('li',[_vm._v("Porcentagem TDA: "+_vm._s(_vm.$functions.moneyFormat(item.commission_tda)))]),_c('li',[_vm._v("Porcentagem TDE: "+_vm._s(_vm.$functions.moneyFormat(item.commission_tde)))]),_c('li',[_vm._v("Porcentagem Comissão: "+_vm._s(_vm.$functions.moneyFormat(item.commission_percentage)))]),_c('li',[_vm._v("Frete mínimo: "+_vm._s(_vm.$functions.moneyFormat(item.min_freight)))])])])])]}},{key:"item.default_variation",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.default_variation ? 'Sim' : 'Não'))])]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.default_variation ? 'Padrão' : item.cities.name))])]}},{key:"item.commission_percentage",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.commission_percentage)+"%")])]}},{key:"item.min_freight",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$functions.moneyFormat(item.min_freight)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('span',[(!item.default_variation)?_c('v-icon',{attrs:{"color":"error","title":"Excluir variação"},on:{"click":function ($event) { return _vm.deleteVariation($event, item.id); }}},[_vm._v("mdi-delete")]):_vm._e()],1)]}}],null,true)}),(_vm.show)?_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(_vm.variation.id ? ("Editar variação " + (_vm.variation.name ? _vm.variation.name : '')) : 'Nova variação')+" ")]),_c('v-card-text',[_c('v-form',{ref:"formManageVariation",attrs:{"disabled":_vm.loading || _vm.saving},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[(_vm.items && _vm.items.length > 0 && !_vm.variation.default_variation)?_c('div',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Nome","rules":[
                    function (v) { return !!v || 'Obrigatório'; }
                  ]},model:{value:(_vm.variation.name),callback:function ($$v) {_vm.$set(_vm.variation, "name", $$v)},expression:"variation.name"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Cidade","items":_vm.cities,"item-text":"name","item-value":"id","loading":_vm.cities.length < 1},model:{value:(_vm.variation.city_id),callback:function ($$v) {_vm.$set(_vm.variation, "city_id", $$v)},expression:"variation.city_id"}})],1)],1)],1):_vm._e(),_c('div',[_c('v-row',[_c('v-col',[_c('v-switch',{attrs:{"label":"Desconto TDA"},model:{value:(_vm.variation.tda_discount),callback:function ($$v) {_vm.$set(_vm.variation, "tda_discount", $$v)},expression:"variation.tda_discount"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Desconto TDE"},model:{value:(_vm.variation.tde_discount),callback:function ($$v) {_vm.$set(_vm.variation, "tde_discount", $$v)},expression:"variation.tde_discount"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Desconto ICMS"},model:{value:(_vm.variation.icms_discount),callback:function ($$v) {_vm.$set(_vm.variation, "icms_discount", $$v)},expression:"variation.icms_discount"}})],1),_c('v-col',[_c('v-switch',{attrs:{"label":"Desconto Pedágio"},model:{value:(_vm.variation.toll_discount),callback:function ($$v) {_vm.$set(_vm.variation, "toll_discount", $$v)},expression:"variation.toll_discount"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"TDA %","rules":[
                    function (v) { return !!v || 'Obrigatório'; }
                  ]},model:{value:(_vm.variation.commission_tda),callback:function ($$v) {_vm.$set(_vm.variation, "commission_tda", $$v)},expression:"variation.commission_tda"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"TDE %","rules":[
                    function (v) { return !!v || 'Obrigatório'; }
                  ]},model:{value:(_vm.variation.commission_tde),callback:function ($$v) {_vm.$set(_vm.variation, "commission_tde", $$v)},expression:"variation.commission_tde"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Porcentagem","rules":[
                    function (v) { return !!v || 'Obrigatório'; }
                  ]},model:{value:(_vm.variation.commission_percentage),callback:function ($$v) {_vm.$set(_vm.variation, "commission_percentage", $$v)},expression:"variation.commission_percentage"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Frete mínimo","rules":[
                    function (v) { return !!v || v === 0 || 'Obrigatório'; }
                  ]},model:{value:(_vm.variation.min_freight),callback:function ($$v) {_vm.$set(_vm.variation, "min_freight", $$v)},expression:"variation.min_freight"}})],1)],1)],1)])],1),_c('v-divider'),_c('v-card-actions',[(_vm.loading || _vm.saving)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.loading || _vm.saving},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"success","text":"","disabled":_vm.loading || _vm.saving},on:{"click":_vm.save}},[_vm._v(" Salvar ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }