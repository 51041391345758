<template>
  <div>
    <h1>Nova Parceira</h1>

    <v-btn
      fab
      small
      color="error"
      class="ma-2"
      @click="$router.push({ name: 'partners.index' })"
    >
      <v-icon>mdi-keyboard-return</v-icon>
    </v-btn>

    <Form :saving="saving" @submited="save" />

  </div>
</template>

<script>
import Form from '../components/Form.vue';

  export default {
    components: {
      Form,
    },
    data() {
      return {
        saving: false,
      }
    },
    methods: {
      save(partner) {
        this.saving = true;
        this.$http.post(`api/partners`, partner)
          .then(() => {
            this.saving = false;
            this.$toast.success('Salvo com sucesso');
            this.$router.push({ name: 'partners.index' });
          }).catch(err => {
            this.saving = false;
            console.log(err);
            this.$toast.error('Erro ao salvar');
          });
        }
    },
  }
</script>