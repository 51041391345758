export default {
	set_token(state, token) {
		state.token = token;
	},
	set_user(state, user) {
		state.user = user;
	},
	set_current_cnpj(state, current_cnpj) {
		state.current_cnpj = current_cnpj;
	},
	set_date_deliveries(state, dates) {
		state.dateDeliveries.dateFrom = dates[0];
		state.dateDeliveries.dateTo = dates[1];
	},
	set_items_per_page(state, items) {
		state.ctesPerPage = items;
	},
	set_ctes_search(state, search) {
		state.ctesSearch = search;
	},
	set_colors(state, colors) {
		state.colors.ok = colors.ok;
		state.colors.atrasadas = colors.atrasadas;
		state.colors.retido = colors.retido;
		state.colors.hoje = colors.hoje;
		state.colors.ocorrencia = colors.ocorrencia;
		state.colors.rota = colors.rota;
	},
	set_current_month(state, month) {
		state.selectedMonth = month;
	},
	set_current_year(state, year) {
		state.selectedYear = year;
	},
	set_account(state, data) {
		state.account = data;
	},
	set_partners(state, partners) {
		state.partners = partners;
	},
	set_table_size(state, tableSize) {
		state.tableSize = tableSize;
	}
};
